import { ethers } from "ethers";
import React, { Component } from "react";
import getContract from "../services/contract";
import './login.css';
import withWrapper from '../helpers/withWrapper'
import getWallet from '../services/wallet';

class Login extends Component {
  state = {
    id: '',
    userAddress: '',
    isConnected: false,
    lastId: 0,
    totalValue: 0
  };

  connectWallet = async () => {
    const wallet = await getWallet();
    if(!wallet.isReadOnly) {
        if(wallet.isWrongNetwork) {
          alert('Switch to Polygon Network On Metamask or Trustwallet');
          return;
        }
        const browserAddresses = await wallet.provider.listAccounts();
        this.setState({ userAddress: browserAddresses[0], isConnected: true })
    } else {
        alert('Wallet not connected');
    }
  }

  async componentDidMount() {
    await this.connectWallet();
    const contract = await getContract();
    const lastId = ethers.BigNumber.from(await contract.lastIDCount()).toNumber();
    let totalInvestedBN = await contract.totalValueInvested();
    let totalValue = ethers.BigNumber.from(
      totalInvestedBN.div(ethers.BigNumber.from("1000000"))
    ).toNumber();
    // const lastId = ethers.BigNumber.from().toNumber();
    this.setState({lastId, totalValue})
    console.log(this.state);
  }

  handleViewClick = async () => {
    const contract = await getContract();
    const id = this.state.id;
    const lastId = ethers.BigNumber.from(await contract.lastIDCount()).toNumber();
    if(lastId < id) {
      alert(`User Id ${id} not found`);
      return;
    }
    this.props.router.navigate('/dashboard/' + id);
  };

  handleLogin = async () => {
    const wallet = await getWallet();
    if(!wallet.isReadOnly) {
        const browserAddresses = await wallet.provider.listAccounts();
        const contract = await getContract();
        const id = ethers.BigNumber.from(await contract.getIdByAddress(browserAddresses[0])).toNumber();
        if(id == 0) {
          alert('No Id found with your connected wallet make sure switch to registered account on metamask');
          return;
        }
        this.props.router.navigate('/dashboard/' + id);
    }
  };

  render() {
    return (
        <div id="__next1">
          <div className="flex relative bg-main-bg flex-col items-center justify-center w-full min-h-screen text-white-500 px-10 sm:px-0 overflow-hidden pt-16">
            <header style={{background: 'black'}} className="fixed top-0 w-full pb-2.5 pt-2.5 px-10 z-30 bg-gray-900 sm:px-5 lg:border-b lg:border-white-100">
              <nav className="z-10 w-full max-w-desktop-preview-bar m-auto header-border-b">
                <div className="flex items-center justify-between">
                  <a href="/" className="header-link">
                  <img style={{width: '230px', height: '60px'}} src="/logo/logo-name-and-tagline.png" className="light w-20" alt="" />
                  <img style={{width: '230px', height: '60px'}} src="/logo/logo-name-and-tagline.png" className="dark w-20" alt="" />
                  </a>
                  <div className="flex justify-end items-center ml-auto">
                    {this.state.userAddress == '' ? 
                    <button onClick={this.connectWallet}
                      className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-2.5 bg-black-light hover:bg-line-gray active:bg-active-gray text-white font-normal rounded items-center"
                      id="connectWallet"
                    >
                      Connect wallet
                    </button> : 
                    <div className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-2.5 bg-black-light hover:bg-line-gray active:bg-active-gray text-white font-normal rounded items-center">
                      {this.state.userAddress}
                    </div>
                    }
                  </div>
                </div>
              </nav>
              <div className="hidden justify-start items-start bg-main-bg pb-5 pt-7.5 transition duration-500 ease-in-out bg-main-bg absolute top-0 left-0 z-20 inset-0 h-screen w-screen z-999999 -translate-x-120% lg:flex flex-col lg:py-2.5">
                <div
                  className="w-full flex flex-col flex-1"
                  style={{height: '757px'}}
                >
                  <div className="flex justify-between items-center w-full px-10 sm:px-5">
                    <a href="/">
                      <svg
                        className="block mr-2.5"
                        width="163"
                        height="30"
                        viewBox="0 0 679 136"
                      >
                      </svg>
                    </a>
                    <button className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-0 py-0 bg-black-light rounded-full w-10 h-10 hover:bg-line-gray active:bg-active-gray hidden lg:flex flex-col">
                      <span className="w-4 border-t border-white -mb-px rotate-45"></span>
                      <span className="w-4 border-t border-white -rotate-45"></span>
                    </button>
                  </div>
                  <div className="w-full h-full pl-10 pr-7.5 lg:pr-0 lg:pl-10 sm:pl-5 overflow-auto">
                    <div className="flex flex-1 flex-col h-full w-full overflow-y-auto space-y-2.5 lg:space-y-0">
                      <div className="flex flex-1 flex-col w-full h-full overflow-y-auto space-y-2.5 lg:space-y-0 lg:mb-5">
                        <a href="/dashboard">
                          <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                            <div className="flex items-center text-left">
                              <svg
                                className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                                viewBox="0 0 24 24"
                                stroke="#fff"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 4H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1ZM19 4h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1ZM9 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1ZM19 14h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1Z"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                              <span className="text-white-500 text-base  ml-5 false">
                                Dashboard
                              </span>
                            </div>
                          </button>
                        </a>
                        <a href="/partners">
                          <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                            <div className="flex items-center text-left">
                              <svg
                                className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                                viewBox="0 0 16 16"
                                fill="none"
                                stroke="#fff"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                                  strokeWidth="1.333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                              <span className="text-white-500 text-base  ml-5 false">
                                Partners
                              </span>
                            </div>
                          </button>
                        </a>
                        <a href="/stats">
                          <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                            <div className="flex items-center text-left">
                              <svg
                                className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                                viewBox="0 0 24 24"
                                stroke="#fff"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16 12h5m-9-9v5-5ZM8.929 14.582 5.5 17.5M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                              <span className="text-white-500 text-base  ml-5 false">
                                Stats
                              </span>
                            </div>
                          </button>
                        </a>
                        <a href="/targets">
                          <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                            <div className="flex items-center text-left">
                              <svg
                                className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                                stroke="#fff"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                              <span className="text-white-500 text-base  ml-5 false">
                                Targets
                              </span>
                              <span className="absolute top-1 right-1.5 text-orange text-sm lg:bg-orange-200 lg:px-2.5 lg:py-1 lg:rounded lg:top-1/2 lg:-translate-y-1/2 lg:right-5">
                                Beta
                              </span>
                            </div>
                          </button>
                        </a>
                        <a href="/information">
                          <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                            <div className="flex items-center text-left">
                              <svg
                                className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                                viewBox="0 0 24 24"
                                stroke="#fff"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13 7a2 2 0 0 1 2 2v12l-5-3-5 3V9a2 2 0 0 1 2-2h6Z"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M9.265 4A2 2 0 0 1 11 3h6a2 2 0 0 1 2 2v12l-1-.6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                              <span className="text-white-500 text-base  ml-5 false">
                                Information
                              </span>
                            </div>
                          </button>
                        </a>
                        <a href="/tbots">
                          <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                            <div className="flex items-center text-left">
                              <svg
                                className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                                viewBox="0 0 24 24"
                                stroke="#fff"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="m15 10-4 4 6 6 4-16-18 7 4 2 2 6 3-4"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                              <span className="text-white-500 text-base  ml-5 false">
                                Telegram bots
                              </span>
                            </div>
                          </button>
                        </a>
                        <a href="/messages">
                          <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                            <div className="flex items-center text-left">
                              <svg
                                className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="m3 20 1.3-3.9c-1.124-1.662-1.53-3.63-1.144-5.538.386-1.908 1.54-3.626 3.244-4.836 1.704-1.21 3.845-1.827 6.024-1.739 2.179.089 4.248.877 5.821 2.22 1.574 1.342 2.546 3.147 2.735 5.078.19 1.931-.417 3.858-1.706 5.422-1.29 1.564-3.173 2.659-5.302 3.08-2.13.422-4.358.142-6.272-.787L3 20"
                                  stroke="#fff"
                                  strokeOpacity=".5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M12 12v.01M8 12v.01M16 12v.01"
                                  stroke="#fff"
                                  strokeOpacity=".5"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                              <span className="text-white-500 text-base  ml-5 false">
                                Messages
                              </span>
                            </div>
                          </button>
                        </a>
                        <div className="hidden lg:block" href="undefined">
                          <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                            <div className="flex items-center text-left">
                              <svg
                                className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                                viewBox="0 0 20 20"
                                fill="none"
                                stroke="#fff"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.333 14.167a5.833 5.833 0 1 0 0-11.667 5.833 5.833 0 0 0 0 11.667ZM17.5 17.5l-5-5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                              <span className="text-white-500 text-base  ml-5 false">
                                Red Rhino Account search
                              </span>
                            </div>
                            <svg
                              className="stroke-current text-white-500 hidden lg:flex"
                              width="8"
                              height="14"
                              stroke="#fff"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m1 13 6-6M1 1l6 6"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </button>
                        </div>
                        <a className="hidden lg:block" href="/">
                          <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent bg-black-light lg:bg-transparent lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                            <div className="flex items-center text-left">
                              <svg
                                className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                                width="24"
                                height="24"
                                stroke="#fff"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14 8V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-2M7 12h14m0 0-3-3m3 3-3 3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                              <span className="text-white-500 text-base  ml-5 text-white-900">
                                Log out
                              </span>
                            </div>
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:px-5 lg:pb-7.5">
                    <a target="_blank" href="https://oldRRN.redrhino.io">
                      <button className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-3 bg-light-purple hover:bg-hover-purple active:bg-active-purple bg-purple rounded-mini px-3 w-full justify-between mb-2.5 lg:mb-0">
                        <span className="text-white text-base font-normal">
                          Old interface
                        </span>
                        <svg
                          className="h-5"
                          viewBox="0 0 46 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 14C1 6.82 6.82 1 14 1h18c7.18 0 13 5.82 13 13s-5.82 13-13 13H14C6.82 27 1 21.18 1 14Z"
                            stroke="#fff"
                          ></path>
                          <circle cx="14" cy="14" r="9" fill="#fff"></circle>
                        </svg>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </header>
            <div className="flex flex-col flex-1 w-full max-w-desktop-login mt-7.5 sm:mt-0">
              <div className="flex flex-col">
                <div className="grid grid-cols-3 gap-10 mt-5 lg:grid-cols-1 lg:gap-5 z-10 sm:px-5">
                  <div className="w-full">
                    <div className="flex flex-col p-5 bg-black-light rounded desktop-infoblock-base sm:mobile-infoblock-base">
                      <div className="flex items-center">
                        <span className="text-white text-xl sm:whitespace-nowrap">
                          Members total
                        </span>
                      </div>
                      <span className="text-white text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5">
                        {this.state.lastId}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col p-5 bg-black-light rounded desktop-infoblock-base sm:mobile-infoblock-base">
                    <div className="flex items-center">
                      <span className="text-white text-xl sm:whitespace-nowrap">
                        Total invested, USDC
                      </span>
                    </div>
                    <span className="text-white text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5">
                      {this.state.totalValue}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col mb-15 mt-15">
                  <div className="flex flex-col">
                    <div className="flex flex-col sm:px-5">
                      <span className="text-white text-3xl font-bold ">
                        View Account
                      </span>
                      <span className="text-white-500 text-base mt-1 mb-7.5">
                        Enter ID to preview and account details.
                      </span>
                    </div>
                    <div className="flex sm:flex-col">
                      <div className="flex flex-col flex-1 sm:w-full bg-black-light rounded sm:rounded-none p-7.5 sm:p-5 mr-10 sm:mr-0 sm:mb-5">
                        <span className="text-white mb-3">Enter ID</span>
                        <div className="flex sm:flex-col">
                          <input onChange={(e) => this.setState({id: e.target.value})}
                            value={this.state.id}
                            style={{background: 'white', color: 'black', fontSize: '24px'}}
                            className="w-full bg-white-100 border-2 border-transparent rounded-mini py-3 px-2 outline-none focus:border-2 focus:border-black flex-1 mr-5 sm:mr-0 sm:mb-3.5 py-2"
                            id="idbyPlan"
                            type="number"
                          />
                          <button style={{background: 'white', color: 'grey'}}
                            onClick={this.handleViewClick}
                            className="flex justify-center items-center text-center text-base font-bold text-black rounded-mini sm:text-sm outline-none px-5 py-3 bg-blue-500 hover:bg-blue-700 active:bg-active-main-blue rounded-mini">
                            View
                          </button>
                        </div>
                      </div>
                      <div className="w-1/3 min-w-320px sm:w-full sm:px-5">
                        <div className="flex flex-col w-full rounded bg-black-light p-7.5 relative">
                          <span style={{fontSize: '16px', fontWeight: 'bold'}} className="text-white text-center mb-3">
                            Login to your account by wallet
                          </span>
                            <button onClick={this.handleLogin} className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-3 bg-main-bg hover:bg-hover-main-bg active:bg-active-main-bg sm:w-full">
                              Login
                            </button>

                          <img
                            src="img/favicon/apple-icon-120x120.png"
                            width="90"
                            className="sm:hidden absolute top-1/2 right-0 transform -translate-y-1/2"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
           
              </div>

            </div>
          </div>
          <footer className="diamond-footer">
          <div className="footer-widget-area">
            <div className="flex justify-center">
              <div className="row">
                <div className="col-lg-3 col-md-4 widget-area logo_clear_first">
                  <div
                    className="widget widget-html"
                    style={{ paddingTop: "30px" }}
                  >
                    {/* <div className="footer-logo">
                      <img
                        src="/logo/1 (1).png"
                        style={{ paddingBottom: "10px", marginTop: "-50px" }}
                      />
                      <span className="text-center" style={{ fontSize: "15px" }}>
                        Copyright © 2022. All Rights Reserved
                      </span>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-4 widget-area">
                  <div className="widget widget-html text-center">
                    <h2 className="widget-title">Stay connected</h2>
                    <div className="socials">
                      <ul>
                        <li>
                          <a target="_blank" href="https://www.facebook.com/forcedmatrix">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://t.me/forcedmatrixdao">
                            <i className="fab fa-telegram-plane"></i>
                          </a>
                        </li>

                        <li>
                          <a target="_blank" href="#">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://medium.com/@forcedmatrix" target="_blank" >
                            <i className="fab fa-medium-m"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 widget-area">
                  <div className="widget widget-html">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        </div>
    );
  }
}

export default withWrapper(Login);