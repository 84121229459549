import { ethers } from "ethers";
import getContract from "./contract";
import levelPrices, {lpFees} from "./levelPrice";
import getTokenContract from "./tokenContract";

export class TransactionService {
  constructor(userId, userAddress) {
    this.userId = userId;
    this.userAddress = userAddress;
  }

  buyLevel = async (level) => {
    try {

      const tokenContract = await getTokenContract();
      const contract = await getContract();

      const userInfoResponse = await contract.userInfos(this.userId);
      const userLastLevel = ethers.BigNumber.from(userInfoResponse["levelBought"]).toNumber();

      if (userLastLevel+1 < level) {
        throw new Error("User has to buy previous slots first");
      }
      const userBalance = await tokenContract.balanceOf(this.userAddress);
      
      // const levelPrice = ethers.BigNumber.from(levelPrices[level-1] * (10**18));
      const levelPrice = ethers.BigNumber.from(levelPrices[level-1] * (10**6));
      if (userBalance.lt(levelPrice)) {
        throw new Error("User has Insufficient balance");
      }
      
      const allowance = await tokenContract.allowance(this.userAddress, contract.address);
      
      if(allowance.lt(levelPrice)) {
        console.log("userLastLevel: ", userLastLevel);
        const tokenAmount = levelPrice;
        const txn = await tokenContract.approve(contract.address, tokenAmount);
        // const txn = await tokenContract.approve(contract.address, tokenAmount, {gasPrice: ethers.utils.parseUnits('100', 'gwei')});
        await txn.wait();
      }
      
      const lpFee = lpFees[level-1];
      
      const transaction = await contract.upgradeLevel(level, {value: ethers.utils.parseEther(lpFee)});
      // const transaction = await contract.upgradeLevel(level, {gasPrice: ethers.utils.parseUnits(wallet.maxFeePerGas.toString(), 'gwei')});
      await transaction.wait();
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  register = async (refId) => {
    try {
      const tokenContract = await getTokenContract();
      const contract = await getContract();

      const refAddress = await contract.getAddressById(refId);
      const refInfoResponse = await contract.userInfos(refId);
      const isValidRef = ethers.BigNumber.from(refInfoResponse["id"]).toNumber() > 0;

      if(!isValidRef) {
        throw new Error("Invalid Upline Id. There is no referrer with this id match");
      }

      const userID = await contract.getIdByAddress(this.userAddress);

      const userInfoResponse = await contract.userInfos(userID);
      const isAlreadyRegistered = ethers.BigNumber.from(userInfoResponse["id"]).toNumber() > 0;
      console.log(isAlreadyRegistered);

      if(isAlreadyRegistered) {
        throw new Error("User is already registered");
      }

      const userBalance = await tokenContract.balanceOf(this.userAddress)

      const levelPrice = ethers.BigNumber.from(ethers.BigNumber.from("1000000").mul(ethers.BigNumber.from(levelPrices[0])));

      // const levelPrice = ethers.utils.formatUnits(levelPrices[0], "wei");
      if (userBalance.lt(levelPrice)) {
        throw new Error("User has Insufficient balance");
      }
      const allowance = await tokenContract.allowance(this.userAddress, contract.address);

      if(allowance.lt(levelPrice)) {
        const tokenAmount = levelPrice;
        const txn = await tokenContract.approve(contract.address, tokenAmount);
        // const txn = await tokenContract.approve(contract.address, tokenAmount, {gasPrice: ethers.utils.parseUnits('100', 'gwei')});
        await txn.wait();
      }
      const lpFee = lpFees[0];
      
      const transaction = await contract.register(refId, { value: ethers.utils.parseEther(lpFee)});
      // const transaction = await contract.register(refId, {gasPrice: ethers.utils.parseUnits(wallet.maxFeePerGas.toString(), 'gwei')});
      await transaction.wait();
      const userId = ethers.BigNumber.from(await contract.getIdByAddress(this.userAddress)).toNumber();
      return userId;
    } catch (error) {
      throw error;
    }
  }
}