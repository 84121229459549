import { fmTokenAddress } from "./tokenContract";
const tokenAddress = '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359';
// const tokenAddress = '0xC174Be5bbC0594B031ADb2cCa021E8feeED706f6';
const tokenSymbol = 'USDC';
const tokenDecimals = 6;
// const tokenImage = 'http://placekitten.com/200/300';

const addToken = async () => {
    try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: tokenAddress, // The address that the token is at.
              symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: tokenDecimals, // The number of decimals in the token
                //   image: tokenImage, // A string url of the token logo
            },
          },
        });
      
        if (wasAdded) {
          console.log('Thanks for your interest!');
        } else {
          console.log('Your loss!');
        }
      } catch (error) {
        console.log(error);
      }
}


export const addFMToken = async () => {
  try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: fmTokenAddress, // The address that the token is at.
            symbol: 'FMT', // A ticker symbol or shorthand, up to 5 chars.
            decimals: 6, // The number of decimals in the token
              //   image: tokenImage, // A string url of the token logo
          },
        },
      });
    
      if (wasAdded) {
        console.log('Added!');
      } else {
        console.log('Your loss!');
      }
    } catch (error) {
      console.log(error);
    }
}

export default addToken;