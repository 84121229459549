import React, { Component } from 'react';
import { getOwnerContract }  from '../../services/contract';
import getTokenContract from '../../services/tokenContract'
import { ethers } from 'ethers';
import levelPrices from '../../services/levelPrice';
import getWallet from '../../services/wallet';
import withWrapper from '../../helpers/withWrapper';
import {proxyContract} from "../../services/contract";
import {fmTokenAddress} from "../../services/tokenContract";

class Contract extends Component {

    state = {
    };

    addToMetamask = async () => {
        try {
            // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            const wasAdded = await window.ethereum.request({
              method: 'wallet_watchAsset',
              params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                  address: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359', // The address that the token is at.
                  symbol: 'USDC', // A ticker symbol or shorthand, up to 5 chars.
                  decimals: 6, // The number of decimals in the token
                    //   image: tokenImage, // A string url of the token logo
                },
              },
            });
          
            if (wasAdded) {
              console.log('Thanks for your interest!');
            } else {
              console.log('Your loss!');
            }
          } catch (error) {
            console.log(error);
          }
    }

    render() { 
        return (
            <div className="flex flex-shrink w-full flex-col items-center min-h-screen bg-main pt-20 sm:pt-14">
                    <div className="flex relative flex-1 py-2 pt-8.5 flex-col justify-between w-full px-10 sm:px-0 sm:pt-7.5">
                        {/* Top Panel, Matrix levels and all activies inside below div */}
                        <div className="flex flex-col w-full space-y-10 sm:space-y-5">
                            <div className="text-lg sm:text-md" style={{fontSize: "30px", color: "#C0A059"}}>Forced Matrix Token</div>
                            <div className="text-lg sm:text-md" style={{fontSize: "20px"}}>
                                FM Token Contract Address: <a href={'https://polygonscan.com/address/' + fmTokenAddress} target="_blank">{fmTokenAddress}</a> 
                            </div>
                            <div>
                                <a style={{background: 'white', width:"300px"}} href="https://forcedmatrix.io/F.M. Token PDF Final.pdf" target="_blank" 
                                  
                                  className="flex justify-center items-center text-center text-base font-bold text-black rounded-mini sm:text-sm outline-none px-5 py-4 bg-blue-600 hover:bg-blue-400 active:bg-active-red-600 py-5 !px-10 sm:py-5 sm:mt-7.5">
                                  Download UseCase</a>
                                {/* <button style={{background: 'white'}}
                                        className="flex justify-center items-center text-center text-base font-bold text-black rounded-mini sm:text-sm outline-none px-5 py-4 bg-blue-600 hover:bg-blue-400 active:bg-active-red-600 py-5 !px-10 sm:py-5 sm:mt-7.5"
                                        onClick={this.addToMetamask} id="nextButton" >Download UseCase</button> */}
                            </div>
                            <div className="text-lg sm:text-md" style={{fontSize: "30px", color: "#C0A059"}}>Forced Matrix Contract</div>
                            <div className="text-lg sm:text-md" style={{fontSize: "20px"}}>
                                Forced Matrix Contract: <a href={'https://polygonscan.com/address/' + proxyContract} target="_blank">{proxyContract}</a>
                            </div>
                        </div>
                        {/* Top Panel, Matrix levels and all activies inside above div */}
                    </div>
            </div>
        );
    }
}
 
export default withWrapper(Contract);