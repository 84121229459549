import { ethers } from "ethers";
import React, { Component } from "react";
import getContract from "../../services/contract";
import { Link } from 'react-router-dom';
import transactionTypes from '../../helpers/transactionType';
import withWrapper from '../../helpers/withWrapper';

class Matrix extends Component {
  state = {
    idsWithLevel: new Array(31).fill(0),
    childrenTransactionTypes: []
  };
  constructor(props) {
    super(props);
    this.state.userId = props.router.params.id;
    this.state.level = props.router.params.level;
    this.state.reinvestNo = 0;
    this.props.onSetUserId(this.state.userId);
  }

  handleIdClick = async (userId) => {
    await this.setData(userId);
    this.props.router.navigate('/dashboard/' + userId + '/' + this.state.level);
  }

  handleReinvestClick = async (userId, cycleId) => {
    await this.setData(userId, cycleId);
  }

  async componentDidMount() {
    try {
      await this.setData(this.state.userId);
    } catch (error) {
      console.log(error)
    }
  }

  setData = async (userId, cycleNumber) => {
    const contract = await getContract();
    const userAddress = await contract.getAddressById(userId);

    const latestReinvest = ethers.BigNumber.from(await contract.userReInvestNumbers(userId, this.state.level)).toNumber();
    
    let reinvestNo = latestReinvest;
    if(cycleNumber != undefined) {
      reinvestNo = +cycleNumber;
    }

    
    const activeGoldInfoResponse = await contract.activeGoldInfos(userId, this.state.level);
    const currentParrent = ethers.BigNumber.from(activeGoldInfoResponse["currentParent"]).toNumber();
    
    const transactionTypsesResponse = await contract.getChildrenTreeInfo(userId, this.state.level, reinvestNo);
    let transactions = [];
    console.log(transactionTypsesResponse);
    transactionTypsesResponse.forEach((t) => {
      const transaction = {userId: ethers.BigNumber.from(t["userId"]).toNumber(), type: t["transactionType"]}
      transaction.typeProperties = transactionTypes[transaction.type];
      transactions.push(transaction);
    })
    
    const idsResponse = await contract.getTree(userAddress, this.state.level, reinvestNo);
    const userWithLevel = [];
    idsResponse.forEach((response, index) => {
      const id = ethers.BigNumber.from(response).toNumber();
      userWithLevel[index] = id;
    });
    console.log("Im here");
    this.setState({ idsWithLevel: userWithLevel, reinvestNo: latestReinvest, 
      userAddress, userId, childrenTransactionTypes: transactions, currentParrent });
      
    }
    
  copyToClipboard = (value) => {

  }

  render() {
    return (
      <div className="flex flex-shrink w-full flex-col items-center min-h-screen bg-main pt-20 sm:pt-14">
        <div className="flex relative flex-1 py-2 pt-8.5 flex-col justify-between w-full px-10 sm:px-0 sm:pt-7.5">
          <div className="flex flex-col space-y-10 sm:space-y-7.5 z-0">
            <div className="flex z-10 !mt-0">
              <div className="flex flex-col w-full flex-wrap sm:px-5">
                <div className="flex items-center mb-1.5 sm:mb-2.5">
                  <div className="flex">
                    <a
                      className="mr-1.5 text text-white-300 sm:text-sm"
                      href="#"
                    >
                      <span className="hover:text-white-500">Forced Matrix</span> /
                    </a>
                  </div>
                  <span className="text text-white whitespace-nowrap sm:text-sm">
                  Stage {this.state.level}
                  </span>
                </div>
                <div className="w-full flex justify-between flex-wrap">
                  <div className="flex items-center">
                    <span className="text-two-half text-white font-medium mr-2 sm:text-2xl whitespace-nowrap">
                    Stage {this.state.level}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <a
              className="flex w-full items-center justify-center sm:px-5 z-10"
            >
              <button onClick={() => this.handleIdClick(this.state.currentParrent)} className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-2.5 bg-black-light hover:bg-line-gray active:bg-active-gray !p-5 w-8/12 xl:w-full sm:!p-4 rounded font-normal">
                <span className="text-white text-base !leading-30px sm:text-sm">
                  Upline
                </span>
                <span className="rounded bg-white-100 px-2.5 text-white text-base ml-2.5 !leading-30px sm:text-sm">
                  ID {this.state.currentParrent}
                </span>
              </button>
            </a>
            <div className="">
              <div className="flex flex-col sm:px-5 space-y-10 z-10">
                <div className="flex space-x-10 xl:space-x-0 z-10">
                {(this.state.level > 1) && (
                    <button className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-2.5 bg-black-light hover:bg-line-gray active:bg-active-gray min-w-140px xl:hidden p-7 bg-black-light rounded">
                    <a
                      className="flex items-center justify-center font-normal h-full w-full"
                      href={`/dashboard/${this.state.userId}/${+this.state.level - 1}`}
                    >
                      <span className="text-white text-base mr-2.5">
                        Stage {this.state.level - 1}
                      </span>
                      
                    </a>
                  </button>
                  )}
                  <div className="flex w-full min-h-340px sm:min-h-290px col-span-4 rounded relative  bg-gray-900 hover:bg-gray-800 ">
                    <div className="flex w-full flex-col z-10 p-7.5 sm:p-5">
                      <div className="flex w-full justify-between mb-7.5 z-10">
                        <div className="flex flex-1 items-start justify-start ">
                          <div className="flex items-center space-x-2.5">
                            <span className="text-white-300 text-two-half font-normal sm:text-xl">
                              {this.state.reinvestNo}
                            </span>
                          </div>
                        </div>
                        <div className="flex-1 flex flex-col items-center">
                          <span className="text-white text-two-half font-medium mb-2.5 sm:text-xl">
                            ID {this.state.userId}
                          </span>
                        </div>
                        <div className="flex items-start justify-end flex-1 text-white-300 text-two-half font-normal text-right  sm:text-xl"></div>
                      </div>
                      <div className="flex flex-col w-full h-full z-10">
                        <div className="flex justify-center mb-15 sm:mb-10">
                          <div className="flex w-full items-start justify-evenly mt-7.5 sm:mt-5 first:mt-0">
                            <div className="flex flex-col justify-around items-center w-full">
                            <a onClick={() => this.handleIdClick(this.state.idsWithLevel[1])}>
                                <div className="relative">
                                  { (!this.state.idsWithLevel[1]) ? 
                                    <div className="flex flex-col rounded-full bg-white-100 w-20 h-20 sm:w-15 sm:h-15 items-center justify-center text-white-100 group hover:opacity-75">
                                      {/* <img
                                        src="/logo/dashboard-logo.png"
                                        className="w-7 h-7 sm:h-5 sm:w-5 false"
                                      /> */}
                                    </div> : 
                                    <div className={"flex flex-col rounded-full w-20 h-20 sm:w-15 sm:h-15 items-center justify-center text-white-100 group hover:opacity-75 "
                                        + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[1]).typeProperties.class}>
                                      <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[1]}</span>
                                    </div>
                                  }
                                </div>
                              </a>
                              <div className="flex w-full items-start justify-evenly mt-7.5 sm:mt-5 first:mt-0">
                                <div className="flex flex-col justify-around items-center w-full">
                                <a onClick={() => this.handleIdClick(this.state.idsWithLevel[3])}>
                                    <div className="relative">
                                      { (!this.state.idsWithLevel[3]) ?
                                        <div className="flex flex-col rounded-full bg-white-100 w-20 h-20 sm:w-10 sm:h-10 items-center justify-center text-main-blue text-base group hover:opacity-75">
                                        {/* <img
                                          src="/logo/dashboard-logo.png"
                                          className="w-7 h-7 sm:h-5 sm:w-5 false"
                                        /> */}
                                        </div> : 
                                        <div className={"flex flex-col rounded-full w-20 h-20 sm:w-10 sm:h-10 items-center justify-center text-main-blue text-base group hover:opacity-75 " 
                                          + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[3]).typeProperties.class}>
                                          <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[3]}</span>
                                        </div>
                                      }
                                      
                                    </div>
                                  </a>
                                  <div className="flex w-full items-start justify-evenly mt-7.5 sm:mt-5 first:mt-0">
                                    <div className="flex flex-col justify-around items-center w-full">
                                      <a onClick={() => this.handleIdClick(this.state.idsWithLevel[7])}>
                                        <div className="relative">
                                          { (!this.state.idsWithLevel[7]) ? 
                                          <div className="flex flex-col rounded-full bg-white-100 w-13 h-13 sm:w-6.5 sm:h-6.5 items-center justify-center text-main-blue text-base group hover:opacity-75">
                                            {/* <img
                                              src="/logo/dashboard-logo.png"
                                              className="w-7 h-7 sm:h-5 sm:w-5 false"
                                            /> */}
                                          </div> : 
                                          <div className={"flex flex-col rounded-full w-13 h-13 sm:w-6.5 sm:h-6.5 items-center justify-center text-main-blue text-base group hover:opacity-75 " 
                                            + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[7]).typeProperties.class}>
                                            <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[7]}</span>
                                          </div>
                                          }
                                        </div>
                                      </a>
                                      <div className="flex w-full items-start justify-evenly mt-7.5 sm:mt-5 first:mt-0">
                                        <div className="flex flex-col justify-around items-center false">
                                        <a onClick={() => this.handleIdClick(this.state.idsWithLevel[15])}>
                                            <div className="relative">
                                              { (!this.state.idsWithLevel[15]) ? 
                                              <div className="flex flex-col rounded-full bg-white-100 w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false">
                                                {/* <img
                                                  src="/logo/dashboard-logo.png"
                                                  className="w-4.5 h-4.5 sm:h-3 sm:w-3 false"
                                                /> */}
                                              </div> :
                                              <div className={"flex flex-col rounded-full w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false " 
                                                + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[15]).typeProperties.class}>
                                                <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[15]}</span>
                                              </div>
                                              }
                                            </div>
                                          </a>
                                        </div>
                                        <div className="flex flex-col justify-around items-center false">
                                        <a onClick={() => this.handleIdClick(this.state.idsWithLevel[16])}>
                                            <div className="relative">
                                            { (!this.state.idsWithLevel[16]) ? 
                                              <div className="flex flex-col rounded-full bg-white-100 w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false">
                                                {/* <img
                                                  src="/logo/dashboard-logo.png"
                                                  className="w-4.5 h-4.5 sm:h-3 sm:w-3 false"
                                                /> */}
                                              </div> :
                                              <div className={"flex flex-col rounded-full w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false " 
                                                + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[16]).typeProperties.class} >
                                                <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[16]}</span>
                                              </div>
                                            }
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex flex-col justify-around items-center w-full">
                                    <a onClick={() => this.handleIdClick(this.state.idsWithLevel[8])}>
                                        <div className="relative">
                                        { (!this.state.idsWithLevel[8]) ? 
                                          <div className="flex flex-col rounded-full bg-white-100 w-13 h-13 sm:w-6.5 sm:h-6.5 items-center justify-center text-main-blue text-base group hover:opacity-75">
                                            {/* <img
                                              src="/logo/dashboard-logo.png"
                                              className="w-7 h-7 sm:h-5 sm:w-5 false"
                                            /> */}
                                          </div> :
                                          <div className={"flex flex-col rounded-full w-13 h-13 sm:w-6.5 sm:h-6.5 items-center justify-center text-main-blue text-base group hover:opacity-75 " 
                                              + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[8]).typeProperties.class}>
                                            <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[8]}</span>
                                          </div>
                                        }
                                        </div>
                                      </a>
                                      <div className="flex w-full items-start justify-evenly mt-7.5 sm:mt-5 first:mt-0">
                                        <div className="flex flex-col justify-around items-center false">
                                        <a onClick={() => this.handleIdClick(this.state.idsWithLevel[17])}>
                                            <div className="relative">
                                            { (!this.state.idsWithLevel[17]) ? 
                                              <div className="flex flex-col rounded-full bg-white-100 w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false">
                                                {/* <img
                                                  src="/logo/dashboard-logo.png"
                                                  className="w-4.5 h-4.5 sm:h-3 sm:w-3 false"
                                                /> */}
                                              </div> : 
                                              <div className={"flex flex-col rounded-full w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false " 
                                                  + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[17]).typeProperties.class}>
                                                <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[17]}</span>
                                              </div>
                                            }
                                            </div>
                                          </a>
                                        </div>
                                        <div className="flex flex-col justify-around items-center false">
                                        <a onClick={() => this.handleIdClick(this.state.idsWithLevel[18])}>
                                            <div className="relative">
                                            { (!this.state.idsWithLevel[18]) ? 
                                              <div className="flex flex-col rounded-full bg-white-100 w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false">
                                                {/* <img
                                                  src="/logo/dashboard-logo.png"
                                                  className="w-4.5 h-4.5 sm:h-3 sm:w-3 false"
                                                /> */}
                                              </div> : 
                                              <div className={"flex flex-col rounded-full w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false " 
                                                + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[18]).typeProperties.class}>
                                                <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[18]}</span>
                                              </div>
                                            }
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex flex-col justify-around items-center w-full">
                                <a onClick={() => this.handleIdClick(this.state.idsWithLevel[4])}>
                                    <div className="relative">
                                    { (!this.state.idsWithLevel[4]) ?
                                      <div className="flex flex-col rounded-full bg-white-100 w-20 h-20 sm:w-10 sm:h-10 items-center justify-center text-main-blue text-base group hover:opacity-75">
                                        {/* <img
                                          src="/logo/dashboard-logo.png"
                                          className="w-7 h-7 sm:h-5 sm:w-5 false"
                                        /> */}
                                        </div> : 
                                        <div className={"flex flex-col rounded-full w-20 h-20 sm:w-10 sm:h-10 items-center justify-center text-main-blue text-base group hover:opacity-75 "
                                          + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[4]).typeProperties.class}>
                                          <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[4]}</span>
                                        </div>
                                      }
                                      </div> 
                                  </a>
                                  <div className="flex w-full items-start justify-evenly mt-7.5 sm:mt-5 first:mt-0">
                                    <div className="flex flex-col justify-around items-center w-full">
                                    <a onClick={() => this.handleIdClick(this.state.idsWithLevel[9])}>
                                        <div className="relative">
                                        { (!this.state.idsWithLevel[9]) ? 
                                          <div className="flex flex-col rounded-full bg-white-100 w-13 h-13 sm:w-6.5 sm:h-6.5 items-center justify-center text-main-blue text-base group hover:opacity-75">
                                            {/* <img
                                              src="/logo/dashboard-logo.png"
                                              className="w-7 h-7 sm:h-5 sm:w-5 false"
                                            /> */}
                                          </div> :
                                          <div className={"flex flex-col rounded-full w-13 h-13 sm:w-6.5 sm:h-6.5 items-center justify-center text-main-blue text-base group hover:opacity-75 " 
                                              + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[9]).typeProperties.class}>
                                            <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[9]}</span>
                                          </div>
                                        }
                                        </div>
                                      </a>
                                      <div className="flex w-full items-start justify-evenly mt-7.5 sm:mt-5 first:mt-0">
                                        <div className="flex flex-col justify-around items-center false">
                                        <a onClick={() => this.handleIdClick(this.state.idsWithLevel[19])}>
                                            <div className="relative">
                                            { (!this.state.idsWithLevel[19]) ? 
                                              <div className="flex flex-col rounded-full bg-white-100 w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false">
                                                {/* <img
                                                  src="/logo/dashboard-logo.png"
                                                  className="w-4.5 h-4.5 sm:h-3 sm:w-3 false"
                                                /> */}
                                              </div> : 
                                              <div className={"flex flex-col rounded-full w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false " 
                                                + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[19]).typeProperties.class}>
                                                <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[19]}</span>
                                              </div>
                                            }
                                            </div>
                                          </a>
                                        </div>
                                        <div className="flex flex-col justify-around items-center false">
                                        <a onClick={() => this.handleIdClick(this.state.idsWithLevel[20])}>
                                            <div className="relative">
                                            { (!this.state.idsWithLevel[20]) ? 
                                              <div className="flex flex-col rounded-full bg-white-100 w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false">
                                                {/* <img
                                                  src="/logo/dashboard-logo.png"
                                                  className="w-4.5 h-4.5 sm:h-3 sm:w-3 false"
                                                /> */}
                                              </div> : 
                                              <div className={"flex flex-col rounded-full w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false " 
                                                + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[20]).typeProperties.class}>
                                                <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[20]}</span>
                                              </div>
                                            }
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex flex-col justify-around items-center w-full">
                                    <a onClick={() => this.handleIdClick(this.state.idsWithLevel[10])}>
                                        <div className="relative">
                                        { (!this.state.idsWithLevel[10]) ? 
                                          <div className="flex flex-col rounded-full bg-white-100 w-13 h-13 sm:w-6.5 sm:h-6.5 items-center justify-center text-main-blue text-base group hover:opacity-75">
                                            {/* <img
                                              src="/logo/dashboard-logo.png"
                                              className="w-7 h-7 sm:h-5 sm:w-5 false"
                                            /> */}
                                          </div> : 
                                          <div className={"flex flex-col rounded-full w-13 h-13 sm:w-6.5 sm:h-6.5 items-center justify-center text-main-blue text-base group hover:opacity-75 " 
                                          + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[10]).typeProperties.class}>
                                            <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[10]}</span>
                                          </div>
                                        }
                                        </div>
                                      </a>
                                      <div className="flex w-full items-start justify-evenly mt-7.5 sm:mt-5 first:mt-0">
                                        <div className="flex flex-col justify-around items-center false">
                                        <a onClick={() => this.handleIdClick(this.state.idsWithLevel[21])}>
                                            <div className="relative">
                                            { (!this.state.idsWithLevel[21]) ? 
                                              <div className="flex flex-col rounded-full bg-white-100 w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false">
                                                {/* <img
                                                  src="/logo/dashboard-logo.png"
                                                  className="w-4.5 h-4.5 sm:h-3 sm:w-3 false"
                                                /> */}
                                              </div> : 
                                              <div className={"flex flex-col rounded-full w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false " 
                                              + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[21]).typeProperties.class}>
                                                <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[21]}</span>
                                              </div>
                                            }
                                            </div>
                                          </a>
                                        </div>
                                        <div className="flex flex-col justify-around items-center false">
                                        <a onClick={() => this.handleIdClick(this.state.idsWithLevel[22])}>
                                            <div className="relative">
                                            { (!this.state.idsWithLevel[22]) ? 
                                              <div className="flex flex-col rounded-full bg-white-100 w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false">
                                                {/* <img
                                                  src="/logo/dashboard-logo.png"
                                                  className="w-4.5 h-4.5 sm:h-3 sm:w-3 false"
                                                /> */}
                                              </div> : 
                                              <div className={"flex flex-col rounded-full w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false " 
                                                + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[22]).typeProperties.class}>
                                                <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[22]}</span>
                                              </div>
                                            }
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col justify-around items-center w-full">
                            <a onClick={() => this.handleIdClick(this.state.idsWithLevel[2])}>
                                <div className="relative">
                                { (!this.state.idsWithLevel[2]) ? 
                                    <div className="flex flex-col rounded-full bg-white-100 w-20 h-20 sm:w-15 sm:h-15 items-center justify-center text-white-100 group hover:opacity-75">
                                    {/* <img
                                      src="/logo/dashboard-logo.png"
                                      className="w-7 h-7 sm:h-5 sm:w-5 false"
                                    /> */}
                                    </div> : 
                                    <div className={"flex flex-col rounded-full w-20 h-20 sm:w-15 sm:h-15 items-center justify-center text-white-100 group hover:opacity-75 " 
                                      + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[2]).typeProperties.class}>
                                      <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[2]}</span>
                                    </div>
                                }
                                  
                                </div>
                              </a>
                              <div className="flex w-full items-start justify-evenly mt-7.5 sm:mt-5 first:mt-0">
                                <div className="flex flex-col justify-around items-center w-full">
                                <a onClick={() => this.handleIdClick(this.state.idsWithLevel[5])}>
                                    <div className="relative">
                                    { (!this.state.idsWithLevel[5]) ?
                                      <div className="flex flex-col rounded-full bg-white-100 w-20 h-20 sm:w-10 sm:h-10 items-center justify-center text-main-blue text-base group hover:opacity-75">
                                        {/* <img
                                          src="/logo/dashboard-logo.png"
                                          className="w-7 h-7 sm:h-5 sm:w-5 false"
                                        /> */}
                                      </div> : 
                                      <div className={"flex flex-col rounded-full w-20 h-20 sm:w-10 sm:h-10 items-center justify-center text-main-blue text-base group hover:opacity-75 " 
                                        + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[5]).typeProperties.class}>
                                        <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[5]}</span>
                                      </div>
                                      } 
                                    </div>
                                  </a>
                                  <div className="flex w-full items-start justify-evenly mt-7.5 sm:mt-5 first:mt-0">
                                    <div className="flex flex-col justify-around items-center w-full">
                                    <a onClick={() => this.handleIdClick(this.state.idsWithLevel[11])}>
                                        <div className="relative">
                                        { (!this.state.idsWithLevel[11]) ? 
                                          <div className="flex flex-col rounded-full bg-white-100 w-13 h-13 sm:w-6.5 sm:h-6.5 items-center justify-center text-main-blue text-base group hover:opacity-75">
                                            {/* <img
                                              src="/logo/dashboard-logo.png"
                                              className="w-7 h-7 sm:h-5 sm:w-5 false"
                                            /> */}
                                          </div> : 
                                          <div className={"flex flex-col rounded-full w-13 h-13 sm:w-6.5 sm:h-6.5 items-center justify-center text-main-blue text-base group hover:opacity-75 " 
                                            + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[1]).typeProperties.class}>
                                            <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[11]}</span>
                                          </div>
                                        }
                                        </div>
                                      </a>
                                      <div className="flex w-full items-start justify-evenly mt-7.5 sm:mt-5 first:mt-0">
                                        <div className="flex flex-col justify-around items-center false">
                                        <a onClick={() => this.handleIdClick(this.state.idsWithLevel[23])}>
                                            <div className="relative">
                                            { (!this.state.idsWithLevel[23]) ? 
                                              <div className="flex flex-col rounded-full bg-white-100 w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false">
                                                {/* <img
                                                  src="/logo/dashboard-logo.png"
                                                  className="w-4.5 h-4.5 sm:h-3 sm:w-3 false"
                                                /> */}
                                              </div> :
                                              <div className={"flex flex-col rounded-full w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false " 
                                                + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[23]).typeProperties.class}>
                                                <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[23]}</span>
                                              </div>
                                            }
                                            </div>
                                          </a>
                                        </div>
                                        <div className="flex flex-col justify-around items-center false">
                                        <a onClick={() => this.handleIdClick(this.state.idsWithLevel[24])}>
                                            <div className="relative">
                                            { (!this.state.idsWithLevel[24]) ? 
                                              <div className="flex flex-col rounded-full bg-white-100 w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false">
                                                {/* <img
                                                  src="/logo/dashboard-logo.png"
                                                  className="w-4.5 h-4.5 sm:h-3 sm:w-3 false"
                                                /> */}
                                              </div> : 
                                              <div className={"flex flex-col rounded-full w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false " 
                                                + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[24]).typeProperties.class}>
                                                <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[24]}</span>
                                              </div>
                                            }
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex flex-col justify-around items-center w-full">
                                    <a onClick={() => this.handleIdClick(this.state.idsWithLevel[12])}>
                                        <div className="relative">
                                        { (!this.state.idsWithLevel[12]) ? 
                                          <div className="flex flex-col rounded-full bg-white-100 w-13 h-13 sm:w-6.5 sm:h-6.5 items-center justify-center text-main-blue text-base group hover:opacity-75">
                                            {/* <img
                                              src="/logo/dashboard-logo.png"
                                              className="w-7 h-7 sm:h-5 sm:w-5 false"
                                            /> */}
                                          </div> :
                                          <div className={"flex flex-col rounded-full w-13 h-13 sm:w-6.5 sm:h-6.5 items-center justify-center text-main-blue text-base group hover:opacity-75 "
                                            + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[12]).typeProperties.class}>
                                            <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[12]}</span>  
                                          </div>
                                        }
                                        </div>
                                      </a>
                                      <div className="flex w-full items-start justify-evenly mt-7.5 sm:mt-5 first:mt-0">
                                        <div className="flex flex-col justify-around items-center false">
                                        <a onClick={() => this.handleIdClick(this.state.idsWithLevel[25])}>
                                            <div className="relative">
                                            { (!this.state.idsWithLevel[25]) ? 
                                              <div className="flex flex-col rounded-full bg-white-100 w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false">
                                                {/* <img
                                                  src="/logo/dashboard-logo.png"
                                                  className="w-4.5 h-4.5 sm:h-3 sm:w-3 false"
                                                /> */}
                                              </div> :
                                              <div className={"flex flex-col rounded-full w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false " 
                                                + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[25]).typeProperties.class}>
                                                <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[25]}</span>
                                              </div>
                                            }
                                            </div>
                                          </a>
                                        </div>
                                        <div className="flex flex-col justify-around items-center false">
                                        <a onClick={() => this.handleIdClick(this.state.idsWithLevel[26])}>
                                            <div className="relative">
                                            { (!this.state.idsWithLevel[26]) ? 
                                              <div className="flex flex-col rounded-full bg-white-100 w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false">
                                                {/* <img
                                                  src="/logo/dashboard-logo.png"
                                                  className="w-4.5 h-4.5 sm:h-3 sm:w-3 false"
                                                /> */}
                                              </div> : 
                                              <div className={"flex flex-col rounded-full w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false "
                                                + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[26]).typeProperties.class}>
                                                <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[26]}</span>
                                              </div>
                                            }
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex flex-col justify-around items-center w-full">
                                <a onClick={() => this.handleIdClick(this.state.idsWithLevel[6])}>
                                    <div className="relative">
                                    { (!this.state.idsWithLevel[6]) ?
                                      <div className="flex flex-col rounded-full bg-white-100 w-20 h-20 sm:w-10 sm:h-10 items-center justify-center text-main-blue text-base group hover:opacity-75">
                                        {/* <img
                                          src="/logo/dashboard-logo.png"
                                          className="w-7 h-7 sm:h-5 sm:w-5 false"
                                        /> */}
                                      </div> : 
                                      <div className={"flex flex-col rounded-full w-20 h-20 sm:w-10 sm:h-10 items-center justify-center text-main-blue text-base group hover:opacity-75 " 
                                        + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[6]).typeProperties.class}>
                                        <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[6]}</span>
                                      </div>
                                      }
                                    </div>
                                  </a>
                                  <div className="flex w-full items-start justify-evenly mt-7.5 sm:mt-5 first:mt-0">
                                    <div className="flex flex-col justify-around items-center w-full">
                                    <a onClick={() => this.handleIdClick(this.state.idsWithLevel[13])}>
                                        <div className="relative">
                                        { (!this.state.idsWithLevel[13]) ? 
                                          <div className="flex flex-col rounded-full bg-white-100 w-13 h-13 sm:w-6.5 sm:h-6.5 items-center justify-center text-main-blue text-base group hover:opacity-75">
                                            {/* <img
                                              src="/logo/dashboard-logo.png"
                                              className="w-7 h-7 sm:h-5 sm:w-5 false"
                                            /> */}
                                          </div> : 
                                          <div className={"flex flex-col rounded-full w-13 h-13 sm:w-6.5 sm:h-6.5 items-center justify-center text-main-blue text-base group hover:opacity-75 "
                                            + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[13]).typeProperties.class}>
                                            <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[13]}</span>
                                          </div>
                                        }
                                        </div>
                                      </a>
                                      <div className="flex w-full items-start justify-evenly mt-7.5 sm:mt-5 first:mt-0">
                                        <div className="flex flex-col justify-around items-center false">
                                        <a onClick={() => this.handleIdClick(this.state.idsWithLevel[27])}>
                                            <div className="relative">
                                            { (!this.state.idsWithLevel[27]) ? 
                                              <div className="flex flex-col rounded-full bg-white-100 w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false">
                                                {/* <img
                                                  src="/logo/dashboard-logo.png"
                                                  className="w-4.5 h-4.5 sm:h-3 sm:w-3 false"
                                                /> */}
                                              </div> : 
                                              <div className={"flex flex-col rounded-full w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false " 
                                                + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[27]).typeProperties.class}>
                                                <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[27]}</span>
                                              </div>
                                            }
                                            </div>
                                          </a>
                                        </div>
                                        <a href="#"></a>
                                        <div className="flex flex-col justify-around items-center false">
                                          <a href="#"></a>
                                          <a onClick={() => this.handleIdClick(this.state.idsWithLevel[28])}>
                                            <div className="relative">
                                            { (!this.state.idsWithLevel[28]) ? 
                                              <div className="flex flex-col rounded-full bg-white-100 w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false">
                                                {/* <img
                                                  src="/logo/dashboard-logo.png"
                                                  className="w-4.5 h-4.5 sm:h-3 sm:w-3 false"
                                                /> */}
                                              </div> : 
                                              <div className={"flex flex-col rounded-full w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false " 
                                                + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[28]).typeProperties.class}>
                                                <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[28]}</span>  
                                              </div>
                                            }
                                            </div>
                                          </a>
                                        </div>
                                        <a href="#"></a>
                                      </div>
                                      <a href="#"></a>
                                    </div>
                                    <a href="#"></a>
                                    <div className="flex flex-col justify-around items-center w-full">
                                      <a href="#"></a>
                                      <a onClick={() => this.handleIdClick(this.state.idsWithLevel[14])}>
                                        <div className="relative">
                                        { (!this.state.idsWithLevel[14]) ? 
                                          <div className="flex flex-col rounded-full bg-white-100 w-13 h-13 sm:w-6.5 sm:h-6.5 items-center justify-center text-main-blue text-base group hover:opacity-75">
                                            {/* <img
                                              src="/logo/dashboard-logo.png"
                                              className="w-7 h-7 sm:h-5 sm:w-5 false"
                                            /> */}
                                          </div> :
                                          <div className={"flex flex-col rounded-full w-13 h-13 sm:w-6.5 sm:h-6.5 items-center justify-center text-main-blue text-base group hover:opacity-75 " 
                                            + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[14]).typeProperties.class}>
                                            <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[14]}</span>
                                          </div>
                                        }
                                        </div>
                                      </a>
                                      <div className="flex w-full items-start justify-evenly mt-7.5 sm:mt-5 first:mt-0">
                                        <div className="flex flex-col justify-around items-center false">
                                        <a onClick={() => this.handleIdClick(this.state.idsWithLevel[29])}>
                                            <div className="relative">
                                            { (!this.state.idsWithLevel[29]) ? 
                                              <div className="flex flex-col rounded-full bg-white-100 w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false">
                                                {/* <img
                                                  src="/logo/dashboard-logo.png"
                                                  className="w-4.5 h-4.5 sm:h-3 sm:w-3 false"
                                                /> */}
                                              </div> : 
                                              <div className={"flex flex-col rounded-full w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false " 
                                                + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[29]).typeProperties.class}>
                                                <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[29]}</span>
                                              </div>
                                            }
                                            </div>
                                          </a>
                                        </div>
                                        <a href="#"></a>
                                        <div className="flex flex-col justify-around items-center false">
                                          <a href="#"></a>
                                          <a onClick={() => this.handleIdClick(this.state.idsWithLevel[30])}>
                                            <div className="relative">
                                            { (!this.state.idsWithLevel[30]) ? 
                                              <div className="flex flex-col rounded-full bg-white-100 w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false">
                                                {/* <img
                                                  src="/logo/dashboard-logo.png"
                                                  className="w-4.5 h-4.5 sm:h-3 sm:w-3 false"
                                                /> */}
                                              </div> :
                                              <div className={"flex flex-col rounded-full w-6.5 h-6.5 sm:w-3 sm:h-3 items-center justify-center text-main-blue text-base group false " 
                                                + this.state.childrenTransactionTypes.find(t => t.userId == this.state.idsWithLevel[30]).typeProperties.class}>
                                                <span className="text-black text-base sm:text-sm">{this.state.idsWithLevel[30]}</span>
                                              </div>
                                            }
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <div className="flex space-x-14 xl:space-x-8 sm:space-x-7.5">
                            <div className="flex flex-col">
                              <span className="text-white text-base sm:text-white-500 sm:text-sm">
                                Partners
                              </span>
                              <div className="flex items-center mt-2.5">
                                <svg
                                  className="stroke-current text-white-500 w-6 h-6"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  stroke="#fff"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                                    strokeWidth={1.333}
                                    strokeLinecap={"round"}
                                    strokeLinejoin={"round"}
                                  ></path>
                                </svg>
                                <span className="text-white text-base ml-2.5 sm:text-sm">
                                  1{" "}
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <span className="text-white text-base sm:text-white-500 sm:text-sm">
                                Cycles
                              </span>
                              <div className="flex items-center mt-2.5">
                                <svg
                                  className="stroke-current text-white-500 w-6 h-6"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  stroke="#2CFF4E"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    clipRule="evenodd"
                                    d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                                  ></path>
                                </svg>
                                <span className="text-white text-base ml-2.5 sm:text-sm">
                                  {this.state.reinvestNo}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* <div className="flex flex-col">
                            <span className="text-white text-base text-right sm:text-white-500 sm:text-sm">
                              Total Stage Revenue
                            </span>
                            <div className="flex items-center mt-2.5 justify-end">
                              <svg
                                className="stroke-current text-white w-6 h-6"
                                viewBox="0 0 20 20"
                                fill="none"
                                stroke="#fff"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  opacity=".5"
                                  strokeLinecap=""
                                  strokeLinejoin={"round"}
                                >
                                  <path d="M14.167 6.667v-2.5a.833.833 0 0 0-.834-.834H5A1.667 1.667 0 0 0 3.333 5m0 0A1.667 1.667 0 0 0 5 6.667h10a.833.833 0 0 1 .833.833V10m-12.5-5v10A1.667 1.667 0 0 0 5 16.667h10a.833.833 0 0 0 .833-.834v-2.5"></path>
                                  <path d="M16.667 10v3.333h-3.334a1.667 1.667 0 0 1 0-3.333h3.334Z"></path>
                                </g>
                              </svg>
                              <span className="text-white text-base ml-2.5 sm:text-sm">
                                {" "}
                                0
                              </span>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="flex absolute rounded -bottom-5 top-5 right-5 left-2.5 bg-opacity-50"></div>
                  </div>
                  {(this.state.level < 15) && (
                  <button className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-2.5 bg-black-light hover:bg-line-gray active:bg-active-gray min-w-140px xl:hidden p-7 bg-black-light rounded">
                    <a
                      className="flex items-center justify-center font-normal h-full w-full"
                      href={`/dashboard/${this.state.userId}/${(this.state.level <= 18) ? (+this.state.level + 1) : 1}`}
                    >
                      <span className="text-white text-base mr-2.5">
                        Stage {(this.state.level <= 18) ? (+this.state.level + 1) : 1}
                      </span>
                      <svg
                        className="flex-shrink-0"
                        width="8"
                        height="14"
                        stroke="#fff"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m1 13 6-6M1 1l6 6"
                          strokeWidth={1.5}
                          strokeLinecap={"round"}
                          strokeLinejoin={"round"}
                        ></path>
                      </svg>
                    </a>
                  </button>
                  )}
                </div>
                <div className="hidden xl:flex justify-between">
                {(this.state.level > 1) && (
                    <button className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-2.5 bg-black-light hover:bg-line-gray active:bg-active-gray min-w-140px xl:hidden p-7 bg-black-light rounded">
                    <a
                      className="flex items-center justify-center font-normal h-full w-full"
                      href={`/dashboard/${this.state.userId}/${+this.state.level - 1}`}
                    >
                      <span className="text-white text-base mr-2.5">
                      Stage {this.state.level - 1}
                      </span>
                      
                    </a>
                  </button>
                  )}
                  {(this.state.level < 15) && (
                  <button className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-2.5 bg-black-light hover:bg-line-gray active:bg-active-gray min-w-140px xl:hidden p-7 bg-black-light rounded">
                    <a
                      className="flex items-center justify-center font-normal h-full w-full"
                      href={`/dashboard/${this.state.userId}/${(this.state.level <= 18) ? (+this.state.level + 1) : 1}`}
                    >
                      <span className="text-white text-base mr-2.5">
                      Stage {(this.state.level <= 18) ? (+this.state.level + 1) : 1}
                      </span>
                      <svg
                        className="flex-shrink-0"
                        width="8"
                        height="14"
                        stroke="#fff"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m1 13 6-6M1 1l6 6"
                          strokeWidth={1.5}
                          strokeLinecap={"round"}
                          strokeLinejoin={"round"}
                        ></path>
                      </svg>
                    </a>
                  </button>
                  )}
                </div>
              </div>
            </div>
            <div className="flex w-full items-center justify-center z-10 sm:px-5">
              <div className="flex bg-black-light p-5 rounded w-full items-center justify-center w-8/12 xl:w-full">
                <div className="flex items-center justify-center w-full">
                  {new Array((this.state.reinvestNo) + 1).fill(1).map((val, index) => {
                    return <div key={index} className="relative mr-5">
                        <a className="text-white text-base sm:text-sm flex items-center justify-center cursor-pointer"
                        onClick={() => this.handleReinvestClick(this.state.userId, index)}
                        >
                        <span className="mr-2">Cycle : {index}</span>
                        
                      </a>
                      </div>
                  })}
                  
                  
                </div>
              </div>
            </div>
            <div className="!mt-15 sm:!mt-7.5 sm:px-5">
              <div className="flex items-center flex-wrap">
                <div className="flex items-center text-white-500 mb-5 mr-7.5 sm:items-start sm:mb-2.5 sm:pr-2.5 sm:mr-0 sm:w-1/2 sm:order-2">
                  <div className="w-5 h-5 flex-shrink-0 mr-2.5 rounded-full bg-white"></div>
                  <span className="text-base sm:text-sm">Direct partner</span>
                </div>
                <div className="flex items-center text-white-500 mb-5 mr-7.5 sm:items-start sm:mb-2.5 sm:pr-2.5 sm:mr-0 sm:w-1/2 sm:order-4">
                  <div className="w-5 h-5 flex-shrink-0 mr-2.5 rounded-full bg-light-blue"></div>
                  <span className="text-base sm:text-sm">
                    Spillover from above
                  </span>
                </div>
                <div className="flex items-center text-white-500 mb-5 mr-7.5 sm:items-start sm:mb-2.5 sm:pr-2.5 sm:mr-0 sm:w-1/2 sm:order-6">
                  <div className="w-5 h-5 flex-shrink-0 mr-2.5 rounded-full bg-orange"></div>
                  <span className="text-base sm:text-sm">
                    Spillover from below
                  </span>
                </div>
                <div className="flex items-center text-white-500 mb-5 mr-7.5 sm:items-start sm:mb-2.5 sm:pr-2.5 sm:mr-0 sm:w-1/2 sm:order-7">
                  <div className="w-5 h-5 flex-shrink-0 mr-2.5 rounded-full bg-green-500"></div>
                  <span className="text-base sm:text-sm">Freeflow</span>
                </div>
                <div className="flex items-center text-white-500 mb-5 mr-7.5 sm:items-start sm:mb-2.5 sm:pr-2.5 sm:mr-0 sm:w-1/2 sm:order-1">
                  <svg
                    className="w-5 h-5 mr-2.5 stroke-current text-white"
                    viewBox="0 0 16 16"
                    fill="none"
                    stroke="#fff"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                      strokeWidth={1.333}
                      strokeLinecap={"round"}
                      strokeLinejoin={"round"}
                    ></path>
                  </svg>
                  <span className="text-base sm:text-sm">Partners on Stage</span>
                </div>
                <div className="flex items-center text-white-500 mb-5 mr-7.5 sm:items-start sm:mb-2.5 sm:pr-2.5 sm:mr-0 sm:w-1/2 sm:order-3">
                  <svg
                    className="w-5 h-5 mr-2.5 stroke-current text-white"
                    viewBox="0 0 20 20"
                    fill="none"
                    stroke="#2CFF4E"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                    ></path>
                  </svg>
                  <span className="text-base sm:text-sm">Stage Cycle</span>
                </div>
              </div>
            </div>
            {/* <div className="sm:rounded-none z-10 flex flex-1 flex-col w-full flex-col bg-black-light rounded overflow-hidden h-full min-h-90 sm:max-h-3/4">
              <div className="overflow-auto">
                <table className="min-w-max w-full table-auto border-white-100">
                  <thead>
                    <tr className="text-white-500 text-xs border-b border-white-100">
                      <th className="p-4 text-left font-normal sm:p-3 ">
                        Type
                      </th>
                      <th className="p-4 text-left font-normal sm:p-3">Date</th>
                      <th className="p-4 text-left font-normal sm:p-3">ID</th>
                      <th className="p-4 text-left font-normal sm:p-3">Hash</th>
                      <th className="p-4 text-left font-normal sm:p-3">
                        Event
                      </th>
                      <th className="p-4 text-right font-normal sm:p-3">TRX</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 text-sm font-light align-baseline">
                    <tr className="border-b border-white-100 last:border-none">
                      <td className="p-4 text-left whitespace-nowrap sm:p-3">
                        <div className="flex w-full">
                          <div className="relative flex items-center justify-center rounded-full bg-white-100 w-11 h-11 sm:w-7.5 sm:h-7.5">
                            <svg
                              className="text-white h-18px w-18px "
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 8.667A3.333 3.333 0 1 0 10 2a3.333 3.333 0 0 0 0 6.667Z"
                                fill="#fff"
                                stroke="#fff"
                                strokeLinecap={"round"}
                                strokeLinejoin={"round"}
                              ></path>
                              <path
                                d="M5 17v-1.667A3.333 3.333 0 0 1 8.333 12h3.334A3.333 3.333 0 0 1 15 15.333V17"
                                fill="#fff"
                              ></path>
                              <path
                                d="M5 17v-1.667A3.333 3.333 0 0 1 8.333 12h3.334A3.333 3.333 0 0 1 15 15.333V17H5Z"
                                stroke="#fff"
                                strokeLinecap={"round"}
                                strokeLinejoin={"round"}
                              ></path>
                            </svg>
                            <span className="absolute top-0 right-0 text-mini leading-3 text-white"></span>
                          </div>
                        </div>
                      </td>
                      <td className=" p-4 text-left whitespace-nowrap sm:p-3">
                        <div className="text-white-500 text-sm ">
                          26-08-2022 02:39 PM
                        </div>
                      </td>
                      <td className="p-4 text-left whitespace-nowrap sm:p-3">
                        <a
                          className="flex items-center justify-center px-2.5 leading-30px bg-blue-100 hover:bg-main-blue-300 text-main-blue rounded font-medium text-base sm:text-sm w-max order-2 text-sm"
                          href="#"
                        ></a>
                      </td>
                      <td className="p-4 text-left whitespace-nowrap sm:p-3">
                        <div className="flex text-white text-sm space-x-5 sm:space-x-3">
                          <span>f154...6a69</span>
                          <button onClick={() => this.copyToClipboard('f154367bcfde84e12e5e67975b6bddf095288eab4791821f7cb7b70010636a69')} >
                            <svg
                              className="h-18px w-18px"
                              viewBox="0 0 20 20"
                              fill="#fff"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M15 6.667H8.332c-.92 0-1.667.746-1.667 1.666V15c0 .92.746 1.667 1.667 1.667h6.666c.92 0 1.667-.747 1.667-1.667V8.333c0-.92-.746-1.666-1.667-1.666Z"></path>
                              <path
                                clipRule="evenodd"
                                d="M3.469 3.468A2.167 2.167 0 0 1 5 2.833h6.666A2.167 2.167 0 0 1 13.834 5v1.667a.5.5 0 0 1-1 0V5a1.167 1.167 0 0 0-1.167-1.167H5.001A1.167 1.167 0 0 0 3.834 5v6.667a1.167 1.167 0 0 0 1.167 1.166h1.666a.5.5 0 1 1 0 1H5.001a2.167 2.167 0 0 1-2.167-2.166V5c0-.575.228-1.126.635-1.532Z"
                              ></path>
                            </svg>
                          </button>
                          <a
                            target="_blank"
                            href="https://tronscan.org/#/transaction/f154367bcfde84e12e5e67975b6bddf095288eab4791821f7cb7b70010636a69"
                          >
                            <svg
                              className="w-18px h-18px"
                              viewBox="0 0 24 24"
                              fill="#fff"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                clipRule="evenodd"
                                d="M4.232 7.232A2.5 2.5 0 0 1 6 6.5h5a.5.5 0 0 1 0 1H6A1.5 1.5 0 0 0 4.5 9v9A1.5 1.5 0 0 0 6 19.5h9a1.5 1.5 0 0 0 1.5-1.5v-5a.5.5 0 0 1 1 0v5a2.5 2.5 0 0 1-2.5 2.5H6A2.5 2.5 0 0 1 3.5 18V9a2.5 2.5 0 0 1 .732-1.768Z"
                              ></path>
                              <path
                                clipRule="evenodd"
                                d="M20.354 3.646a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.708-.708l10-10a.5.5 0 0 1 .708 0Z"
                              ></path>
                              <path
                                clipRule="evenodd"
                                d="M14.5 4a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V4.5H15a.5.5 0 0 1-.5-.5Z"
                              ></path>
                            </svg>
                          </a>
                        </div>
                      </td>
                      <td className="p-4 text-left whitespace-nowrap sm:p-3">
                        <div className="text-white text-sm text-right ">
                          directPaidEv
                        </div>
                      </td>
                      <td className="p-4 text-left whitespace-nowrap sm:p-3">
                        <div className="text-white text-sm text-right ">
                          80 TRX
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-white-100 last:border-none">
                      <td className="p-4 text-left whitespace-nowrap sm:p-3">
                        <div className="flex w-full">
                          <div className="theme-card-bg relative flex items-center justify-center rounded-full bg-yellow-100 w-11 h-11 sm:w-7.5 sm:h-7.5">
                            <svg
                              className="fill-current text-white h-18px w-18px"
                              viewBox="0 0 20 20"
                              fill="#FFA243"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 11.5c-.867.49-1.735 1.394-2.18 2.285a5 5 0 0 0-.487 2.882 5 5 0 0 0 2.882-.486C7.106 15.735 8.01 14.867 8.5 14"
                                fillOpacity=".5"
                              ></path>
                              <path
                                clipRule="evenodd"
                                d="M7.26 12.74a6.667 6.667 0 0 0-3.927-1.906 5 5 0 0 1 4.167-2.5 7.5 7.5 0 0 1 6.667-5 2.5 2.5 0 0 1 2.5 2.5 7.5 7.5 0 0 1-5 6.666 5.001 5.001 0 0 1-2.5 4.167 6.667 6.667 0 0 0-1.906-3.928Zm6.073-5.24a.833.833 0 1 1-1.666 0 .833.833 0 0 1 1.666 0Z"
                              ></path>
                            </svg>
                            <span className="absolute top-0 right-0 text-mini leading-3 fill-current text-yellow"></span>
                          </div>
                        </div>
                      </td>
                      <td className=" p-4 text-left whitespace-nowrap sm:p-3">
                        <div className="text-white-500 text-sm ">
                          26-08-2022 02:39 PM
                        </div>
                      </td>
                      <td className="p-4 text-left whitespace-nowrap sm:p-3">
                        <a
                          className="flex items-center justify-center px-2.5 leading-30px bg-blue-100 hover:bg-main-blue-300 text-main-blue rounded font-medium text-base sm:text-sm w-max order-2 text-sm"
                          href="#"
                        ></a>
                      </td>
                      <td className="p-4 text-left whitespace-nowrap sm:p-3">
                        <div className="flex text-white text-sm space-x-5 sm:space-x-3">
                          <span>f154...6a69</span>
                          <button onClick={() => this.copyToClipboard('f154367bcfde84e12e5e67975b6bddf095288eab4791821f7cb7b70010636a69')} >
                            <svg
                              className="h-18px w-18px"
                              viewBox="0 0 20 20"
                              fill="#fff"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M15 6.667H8.332c-.92 0-1.667.746-1.667 1.666V15c0 .92.746 1.667 1.667 1.667h6.666c.92 0 1.667-.747 1.667-1.667V8.333c0-.92-.746-1.666-1.667-1.666Z"></path>
                              <path
                                clipRule="evenodd"
                                d="M3.469 3.468A2.167 2.167 0 0 1 5 2.833h6.666A2.167 2.167 0 0 1 13.834 5v1.667a.5.5 0 0 1-1 0V5a1.167 1.167 0 0 0-1.167-1.167H5.001A1.167 1.167 0 0 0 3.834 5v6.667a1.167 1.167 0 0 0 1.167 1.166h1.666a.5.5 0 1 1 0 1H5.001a2.167 2.167 0 0 1-2.167-2.166V5c0-.575.228-1.126.635-1.532Z"
                              ></path>
                            </svg>
                          </button>
                          <a
                            target="_blank"
                            href="https://tronscan.org/#/transaction/f154367bcfde84e12e5e67975b6bddf095288eab4791821f7cb7b70010636a69"
                          >
                            <svg
                              className="w-18px h-18px"
                              viewBox="0 0 24 24"
                              fill="#fff"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                clipRule="evenodd"
                                d="M4.232 7.232A2.5 2.5 0 0 1 6 6.5h5a.5.5 0 0 1 0 1H6A1.5 1.5 0 0 0 4.5 9v9A1.5 1.5 0 0 0 6 19.5h9a1.5 1.5 0 0 0 1.5-1.5v-5a.5.5 0 0 1 1 0v5a2.5 2.5 0 0 1-2.5 2.5H6A2.5 2.5 0 0 1 3.5 18V9a2.5 2.5 0 0 1 .732-1.768Z"
                              ></path>
                              <path
                                clipRule="evenodd"
                                d="M20.354 3.646a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.708-.708l10-10a.5.5 0 0 1 .708 0Z"
                              ></path>
                              <path
                                clipRule="evenodd"
                                d="M14.5 4a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V4.5H15a.5.5 0 0 1-.5-.5Z"
                              ></path>
                            </svg>
                          </a>
                        </div>
                      </td>
                      <td className="p-4 text-left whitespace-nowrap sm:p-3">
                        <div className="text-white text-sm text-right ">
                          Payout
                        </div>
                      </td>
                      <td className="p-4 text-left whitespace-nowrap sm:p-3">
                        <div className="text-white text-sm text-right ">
                          10 TRX
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-white-100 last:border-none">
                      <td className="p-4 text-left whitespace-nowrap sm:p-3">
                        <div className="flex w-full">
                          <div className="relative flex items-center justify-center rounded-full bg-white-100 w-11 h-11 sm:w-7.5 sm:h-7.5">
                            <svg
                              className="text-white h-18px w-18px "
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 8.667A3.333 3.333 0 1 0 10 2a3.333 3.333 0 0 0 0 6.667Z"
                                fill="#fff"
                                stroke="#fff"
                                strokeLinecap={"round"}
                                strokeLinejoin={"round"}
                              ></path>
                              <path
                                d="M5 17v-1.667A3.333 3.333 0 0 1 8.333 12h3.334A3.333 3.333 0 0 1 15 15.333V17"
                                fill="#fff"
                              ></path>
                              <path
                                d="M5 17v-1.667A3.333 3.333 0 0 1 8.333 12h3.334A3.333 3.333 0 0 1 15 15.333V17H5Z"
                                stroke="#fff"
                                strokeLinecap={"round"}
                                strokeLinejoin={"round"}
                              ></path>
                            </svg>
                            <span className="absolute top-0 right-0 text-mini leading-3 text-white"></span>
                          </div>
                        </div>
                      </td>
                      <td className=" p-4 text-left whitespace-nowrap sm:p-3">
                        <div className="text-white-500 text-sm ">
                          25-08-2022 03:16 PM
                        </div>
                      </td>
                      <td className="p-4 text-left whitespace-nowrap sm:p-3">
                        <a
                          className="flex items-center justify-center px-2.5 leading-30px bg-blue-100 hover:bg-main-blue-300 text-main-blue rounded font-medium text-base sm:text-sm w-max order-2 text-sm"
                          href="#"
                        ></a>
                      </td>
                      <td className="p-4 text-left whitespace-nowrap sm:p-3">
                        <div className="flex text-white text-sm space-x-5 sm:space-x-3">
                          <span>e3df...81a2</span>
                          <button onClick={() => this.copyToClipboard('e3df14d49397d6358c01491e790b1a88de010c93c5490f9f7d5167e6833c81a2')} >
                            <svg
                              className="h-18px w-18px"
                              viewBox="0 0 20 20"
                              fill="#fff"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M15 6.667H8.332c-.92 0-1.667.746-1.667 1.666V15c0 .92.746 1.667 1.667 1.667h6.666c.92 0 1.667-.747 1.667-1.667V8.333c0-.92-.746-1.666-1.667-1.666Z"></path>
                              <path
                                clipRule="evenodd"
                                d="M3.469 3.468A2.167 2.167 0 0 1 5 2.833h6.666A2.167 2.167 0 0 1 13.834 5v1.667a.5.5 0 0 1-1 0V5a1.167 1.167 0 0 0-1.167-1.167H5.001A1.167 1.167 0 0 0 3.834 5v6.667a1.167 1.167 0 0 0 1.167 1.166h1.666a.5.5 0 1 1 0 1H5.001a2.167 2.167 0 0 1-2.167-2.166V5c0-.575.228-1.126.635-1.532Z"
                              ></path>
                            </svg>
                          </button>
                          <a
                            target="_blank"
                            href="https://tronscan.org/#/transaction/e3df14d49397d6358c01491e790b1a88de010c93c5490f9f7d5167e6833c81a2"
                          >
                            <svg
                              className="w-18px h-18px"
                              viewBox="0 0 24 24"
                              fill="#fff"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                clipRule="evenodd"
                                d="M4.232 7.232A2.5 2.5 0 0 1 6 6.5h5a.5.5 0 0 1 0 1H6A1.5 1.5 0 0 0 4.5 9v9A1.5 1.5 0 0 0 6 19.5h9a1.5 1.5 0 0 0 1.5-1.5v-5a.5.5 0 0 1 1 0v5a2.5 2.5 0 0 1-2.5 2.5H6A2.5 2.5 0 0 1 3.5 18V9a2.5 2.5 0 0 1 .732-1.768Z"
                              ></path>
                              <path
                                clipRule="evenodd"
                                d="M20.354 3.646a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.708-.708l10-10a.5.5 0 0 1 .708 0Z"
                              ></path>
                              <path
                                clipRule="evenodd"
                                d="M14.5 4a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V4.5H15a.5.5 0 0 1-.5-.5Z"
                              ></path>
                            </svg>
                          </a>
                        </div>
                      </td>
                      <td className="p-4 text-left whitespace-nowrap sm:p-3">
                        <div className="text-white text-sm text-right ">
                          Registration
                        </div>
                      </td>
                      <td className="p-4 text-left whitespace-nowrap sm:p-3">
                        <div className="text-white text-sm text-right ">
                          200 TRX
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex p-4 sm:p-2.5">
                <a
                  href="#"
                  className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-3 bg-white-100 hover:bg-white-300 w-full rounded-mini flex justify-center items-center"
                >
                  <svg
                    className="mr-2.5"
                    width="21"
                    height="20"
                    fill="#fff"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M10.5 15.833c3.333 0 6.11-1.944 8.333-5.833-2.222-3.89-5-5.833-8.333-5.833S4.39 6.11 2.167 10c2.222 3.889 5 5.833 8.333 5.833ZM12.167 10a1.667 1.667 0 1 1-3.334 0 1.667 1.667 0 0 1 3.334 0Z"
                    ></path>
                  </svg>{" "}
                  See more
                </a>
              </div>
            </div> */}
          </div>
          <footer style={{ background: "#18191A" }} className="flex lg:flex-col items-center mt-auto justify-between lg:justify-start lg:items-start py-10 w-full lg:p-5 lg:pb-9 pb-0 lg:pb-0 z-10">
            <div className="hidden lg:block mb-7.5">
              <div className="flex space-x-5 w-full">
                <a
                  className="w-6 h-6 sm:w-7 sm:h-7 flex justify-center items-center rounded-full bg-white-100 hover:bg-white-300"
                  target="_blank"
                  href="https://t.me/+juU7UyeaDNpmYzNk"
                >
                  <svg
                    className="w-3 h-3 sm:w-4 sm:h-4"
                    viewBox="0 0 14 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M11.915.776.841 5.168a.4.4 0 0 0 .03.755l2.79.864 1.133 3.503a.4.4 0 0 0 .653.17l1.64-1.523 3.11 2.29a.4.4 0 0 0 .627-.231l2.166-9.294a.8.8 0 0 0-1.075-.926Zm-6.483 6.74-.346 2.072-.8-2.901 7.816-5.1-6.67 5.929Z"
                      fill="#fff"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <span className="text-white-500 text-xs font-normal lg:mb-2.5">
              © 2022 All Rights Reserved
            </span>
          </footer>
        </div>
      </div>
    );
  }
}

export default withWrapper(Matrix);
