import { ethers } from "ethers";

// const defaultRPC = "https://polygon-rpc.com/";
const defaultRPC = "https://polygon-bor.publicnode.com";
// const defaultRPC = "http://127.0.0.1:8545/";

const getProvider = (defaultRpc=false) => {
    if(window.ethereum && !defaultRpc) {
        return new ethers.providers.Web3Provider(window.ethereum);
    }
    return new ethers.providers.JsonRpcProvider(defaultRPC);
};

export default getProvider;


