import React, { Component } from "react";
import Header from "./header";
import SidePanel from "./sidePanel";
import Dashboard from "./dashboard";
import Matrix from "./matrix";
import { ethers } from 'ethers';
import getWallet from '../../services/wallet';
import withWrapper from '../../helpers/withWrapper';
import getContract from "../../services/contract";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contract from "./Contract";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    if(props.router.params.id > 0) {
      this.state.userId = props.router.params.id;
    }
    else {
      this.state.userId = 1;
    }
  }
  state = {
    view: 'dashboard',
    isViewMode: false,
    signer: null,
    isSideBarOpen: false
  };

  handleSetUserId = (id) => {
    this.setState({userId: id});
  }

  connectWallet = async () => {
    const wallet = await getWallet();
    if(!wallet.isReadOnly) {
      this.setState({ signer: wallet.signer });
    }
  }

  sideBarToggle = () => {
    this.setState({isSideBarOpen: !this.state.isSideBarOpen})
  }

  handleWalletConnected = async (contract) => {
    this.setState({ signer: contract.signer });
  }

  handleConnectWallet = () => {
    this.connectWallet();
  }

  handleContractClick = () => {
    this.props.router.navigate('/contracts');
    toast.dismiss()
    this.sideBarToggle()
  }

  handleDashboardClick = () => {
    this.props.router.navigate('/dashboard/' + this.state.userId);
    toast.dismiss()
    this.sideBarToggle()
  }

  handleSwapClick = () => {
    this.props.router.navigate('/swap');
    toast.dismiss()
    this.sideBarToggle()
  }

  handleNavigateToRegister = (userId) => {
    this.props.router.navigate('/register?refId=' + userId);
  }

  handleNavigateToLogin = () => {
    this.props.router.navigate('/login');
  }

  handleSlotClick = (level) => {
    this.props.router.navigate('/dashboard/' + this.state.userId + '/' + level);
    this.setState({ level, view: 'matrix' });
  }
 
  render() {
    return (
      <div id="__next1">
        <ToastContainer position="bottom-right" />
        <div className="relative flex bg-main-bg items-center justify-center min-h-screen min-w-full overflow-hidden">
          <Header
            onConnectWallet={this.handleConnectWallet}
            signer={this.state.signer}
            sideBarToggle={this.sideBarToggle}
            onDisconnect={this.handleNavigateToLogin}
          />
          <div className="flex w-full h-full justify-center max-w-desktop-full">
            <SidePanel 
            onDashboardClick={this.handleDashboardClick} onContractsClick={this.handleContractClick}
            id={this.state.userId} sideBarOpened={this.state.isSideBarOpen}
            />
            <Routes>
              <Route path="dashboard/:id" element={<Dashboard 
                navigateToRegister={this.handleNavigateToRegister}
                onSetUserId={(id) => this.handleSetUserId(id)}
                onSlotClick={this.handleSlotClick}
                isViewMode={this.state.isViewMode}
                userId={this.state.userId}
                onWalletConnected={this.handleWalletConnected}
              />} />
              <Route path="dashboard/:id/:level" element={<Matrix 
                onSetUserId={(id) => this.handleSetUserId(id)}
                router={this.props.router}
                level={this.state.level}
                userId={this.state.userId}
                idsOnLevel={this.state.idsOnLevel}
              />} />
              <Route path="contracts" element={<Contract />} />
            </Routes>
            
          </div>
        </div>
      </div>
    );
  }
}

export default withWrapper(DashboardLayout);
