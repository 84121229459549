import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import getContract from "../services/contract";
import getWallet from "../services/wallet";

import useCss from "../helpers/useCss";
import { ethers } from "ethers";
import addToken, { addFMToken } from '../services/token';
const materialize = "/home/css/materialize.css";
const bootstrap = "/home/css/bootstrap.css";

const Home = () => {
  useCss("stylesheet", materialize);
  useCss("stylesheet", bootstrap);

  let [topUp, setTopUp] = useState("");
  let [totalValue, setTotalValue] = useState(0);
  let [totalFMT, setTotalFMT] = useState(0);
  let [totalMembers, setTotalMembers] = useState(0);

  useEffect(() => {
    async function getData() {
      try {
        const contract = await getContract();
        setTotalMembers(
          ethers.BigNumber.from(await contract.lastIDCount()).toNumber()
        );

        const totalInvestedBN = await contract.totalValueInvested();
        setTotalValue(
          ethers.BigNumber.from(
            totalInvestedBN.div(ethers.BigNumber.from("1000000"))
          ).toNumber()
        );
        const totalFMT = await contract.totalRewardSent();
        setTotalFMT(
          ethers.BigNumber.from(
            totalFMT.div(ethers.BigNumber.from("1000000"))
          ).toNumber()
        )
      } catch (error) {
        console.log("Switch Network to Polygon chain");
      }
    }
    getData();
  }, []);

  const hideTopUp = () => {
    setTopUp("hidden");
  };

  const handleAddUSDC = async () => {
    await addToken();
  }

  const handleAddFmToken = async () => {
    await addFMToken();
  }

  return (
    <>
      <div className={"warning-domain " + topUp} id="warning-domain_id">
        <div>
          <p className="warning-domain__close01">
            {/* <a href="#" className="warning-domain__close1"><u>Close</u></a> */}
          </p>
          <div>
            Forced Matrix DAO (Decentralized Autonomous Organization) is a
            networking platform based on smart contracts that connect people
            from all over the world and opens the limitless possibilities of a
            new economic and financial system.
          </div>
          <a onClick={hideTopUp} className="warning-domain__close"></a>
        </div>
      </div>
      <div className="wrapper" id="top">
        <header className="header" style={{ padding: "0px" }}>
          <div className="header-wrap"></div>
        </header>
        <div className="midd-container">
          <div
            className="hero-main platinum-layout white-sec"
            style={{ backgroundImage: "url(/home/images/banner-5.jpg)" }}
          >
            <div className="container">
              <div className="row row-reverse align-items-center">
                <div className="col-sm-12 col-md-6" data-wow-delay="0.5s">
                  <div className="platinum-animation start-animation">
                    <div className="platinum-move-1"></div>
                    <div className="platinum-move-2"></div>
                    <div className="platinum-move-3"></div>
                    <div className="platinum-move-4"></div>
                    <div className="platinum-move-5"></div>
                    <div className="top-part">
                      <div className="coin-icon"></div>
                    </div>
                    <div className="millde-part"></div>
                    <div className="base">
                      <div className="lines active">
                        <span className="l-1"></span>
                        <span className="l-2"></span>
                        <span className="l-3"></span>
                        <span className="l-4"></span>
                        <span className="l-5"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 mobile-center text-center">
                  <div className="">
                    <img src="/logo/1.png" width="100%" className="logo" />
                  </div>
                  <h1 style={{ fontWeight: "bolder", fontSize: "40px" }}>
                    Worlds <span style={{ color: "#ffc107" }}>#1</span>{" "}
                    <span>DAO Networking Protocol</span>
                  </h1>
                  <br />
                  <p
                    className="lead"
                    style={{ fontWeight: "bold", fontSize: "16px" }}
                  >
                    {/* <br /> Fully Decentralized{" "} */}
                  </p>
                  <div
                    className="hero-btns heading-content__btns flex text-center"
                    style={{ paddingTop: "55px", justifyContent: "center" }}
                  >
                    <div className="btn-wrap text-center">
                      <a
                        href="/register"
                        className="def-btn text-center def-blue login__button"
                        style={{
                          whiteSpace: "nowrap",
                          background: "#5B8ABE",
                          display: "inline-block",
                          minWidth: "240px",
                          height: "50px",
                          lineHeight: "50px",
                        }}
                      >
                        REGISTRATION
                      </a>
                    </div>
                    <div className="btn-wrap text-center">
                      <a
                        href="/login"
                        className="def-btn text-center def-blue login__button"
                        style={{
                          whiteSpace: "nowrap",
                          background: "#5B8ABE",
                          display: "inline-block",
                          minWidth: "240px",
                          height: "50px",
                          lineHeight: "50px",
                        }}
                      >
                        LOGIN{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="p-tb white-sec"
            style={{
              backgroundImage: "url('/home/images/tr-circle-bg.png')",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#000000",
            }}
          >
            <div className="container">
              <div className="sec-title text-center">
                <h2
                  style={{
                    color: "#fbbd18",
                    fontWeight: "bolder",
                    fontSize: "40px",
                  }}
                >
                  International community
                </h2>
                <h3 style={{ fontWeight: "bolder", fontSize: "40px" }}>
                  Global Decentralized Ecosystem
                </h3>
              </div>
              <div className="sub-txt text-center">
                <p style={{ fontSize: "20px" }}>
                  Decentralized networking platform based on smart contracts
                  that{" "}
                  <span style={{ color: "#ffc107" }}>connects people </span>from
                  all over the world and opens the limitless possibilities of a{" "}
                  <span style={{ color: "#ffc107" }}>
                    new economic financial system
                  </span>
                  .
                </p>
              </div>
              <div className="btn-wrap text-center mb-5">
                <a
                  href="/Token.pdf"
                  target="_blank"
                  className="def-btn m-2 text-center def-blue"
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: "1.2em",
                    background: "#524ABE",
                    display: "inline-block",
                    minWidth: "200px",
                    width: "15em",
                    height: "50px",
                    lineHeight: "50px",
                  }}
                >
                  Token UseCase PDF{" "}
                </a>
                <a
                  href="/Brochure.pdf"
                  target="_blank"
                  className="def-btn m-2 text-center def-blue"
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: "1.2em",
                    background: "#5B8ABE",
                    display: "inline-block",
                    minWidth: "200px",
                    width: "15em",
                    height: "50px",
                    lineHeight: "50px",
                  }}
                >
                  Dapp Guide PDF{" "}
                </a>
              </div>
            </div>
            <div className="w-full mb-5">
              <img src="/logo/partners.jpeg" width="100%" className="logo" />
              <div className="flex justify-center mt-5">
                <a
                  href="/Press_article.pdf"
                  target="_blank"
                  className="def-btn m-2 text-center def-blue"
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: "2rem",
                    background: "#CCAD22",
                    display: "inline-block",
                    minWidth: "200px",
                    width: "30rem",
                    height: "50px",
                    lineHeight: "50px",
                  }}
                >
                  Press Article PDF{" "}
                </a>
              </div>
            </div>
            <div className="container">
              <div className="benefits-boxes row">
                <div className="col-md-4">
                  <div
                    className="item wow fadeInUp"
                    style={{ height: "350px", borderRadius: "30px" }}
                  >
                    <div className="bf-details">
                      <div className="bf-image">
                        <img
                          src="/home/images/icon-1.png"
                          alt="Read Time Update"
                        />
                      </div>
                      <h3
                        style={{
                          color: "#fbbd18",
                          fontFamily: "'Neuron Angled', 'sans-serif'",
                          fontWeight: "bolder",
                        }}
                      >
                        Zero Risk
                      </h3>
                      <p style={{ fontWeight: "bolder" }}>
                        The human factor is excluded. The smart contract does
                        not depend on anyone, and there is no way to stop the
                        platform. All payments are made using USDC on the
                        polygon blockchain network.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="item wow fadeInUp"
                    style={{ height: "350px", borderRadius: "30px" }}
                  >
                    <div className="bf-details">
                      <div className="bf-image">
                        <img src="/home/images/icon-2.png" alt="Cloud Based" />
                      </div>
                      <h3
                        style={{
                          color: "#fbbd18",
                          fontFamily: "'Neuron Angled', 'sans-serif'",
                          fontWeight: "bolder",
                        }}
                      >
                        Instant transactions
                      </h3>
                      <p style={{ fontWeight: "bolder" }}>
                        The profit routes from other members directly into your
                        personal wallet. There is no hoarding in the system, the
                        income belongs only to you
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="item wow fadeInUp"
                    style={{ height: "350px", borderRadius: "30px" }}
                    data-wow-delay="0.1s"
                  >
                    <div className="bf-details">
                      <div className="bf-image">
                        <img
                          src="/home/images/icon-3.png"
                          alt="No transaction fees"
                        />
                      </div>
                      <h3
                        style={{
                          color: "#fbbd18",
                          fontFamily: "'Neuron Angled', 'sans-serif'",
                          fontWeight: "bolder",
                        }}
                      >
                        Immutability of conditions
                      </h3>
                      <p style={{ fontWeight: "bolder" }}>
                        Nobody can exclude you from the platform, because there
                        is no such function in the contract. And the information
                        recorded in the network blocks cannot be changed
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="item wow fadeInUp"
                    style={{ height: "350px", borderRadius: "30px" }}
                    data-wow-delay="0.2s"
                  >
                    <div className="bf-details">
                      <div className="bf-image">
                        <img
                          src="/home/images/icon-5.png"
                          alt="Strong teams & Advisors"
                        />
                      </div>
                      <h3
                        style={{
                          color: "#fbbd18",
                          fontFamily: "'Neuron Angled', 'sans-serif'",
                          fontWeight: "bolder",
                        }}
                      >
                        Transparency and Anonymity
                      </h3>
                      <p style={{ fontWeight: "bolder" }}>
                        The smart contract is public. Anyone can see the code
                        and the entire transaction history. This guarantees the
                        integrity of the system and real project statistics.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="item wow fadeInUp"
                    style={{ height: "350px", borderRadius: "30px" }}
                    data-wow-delay="0.1s"
                  >
                    <div className="bf-details">
                      <div className="bf-image">
                        <img
                          src="/home/images/icon-4.png"
                          alt="Instant operations"
                        />
                      </div>
                      <h3
                        style={{
                          color: "#fbbd18",
                          fontFamily: "'Neuron Angled', 'sans-serif'",
                          fontWeight: "bolder",
                        }}
                      >
                        Decentralization
                      </h3>
                      <p style={{ fontWeight: "bolder" }}>
                        There are no managers or administrators, there are only
                        the creators who are equal participants in the project,
                        like everyone else
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="item wow fadeInUp"
                    style={{ height: "350px", borderRadius: "30px" }}
                    data-wow-delay="0.2s"
                  >
                    <div className="bf-details">
                      <div className="bf-image">
                        <img
                          src="/home/images/icon-6.png"
                          alt="Protects the identity"
                        />
                      </div>
                      <h3
                        style={{
                          color: "#fbbd18",
                          fontFamily: "'Neuron Angled', sans-serif",
                          fontWeight: "bolder",
                        }}
                      >
                        100% online
                      </h3>
                      <p style={{ fontWeight: "bolder" }}>
                        All funds are transferred between members, there are no
                        hidden fees. The contract balance is always zero
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sec-title text-center">
              <h3 style={{ fontWeight: "bolder", fontSize: "40px" }}>
                How Do I Start Earning With Forced Matrix DAO?
              </h3>
            </div>
            <div className="text-center">
              <h3 style={{ fontSize: "20px" }}>
                Click Button To Add Polygon USDC Contract Address To Your
                MetaMask Wallet Automatically
              </h3>
            </div>
            <div className="btn-wrap text-center mt-4 sec-title">
              <button
                onClick={handleAddUSDC}
                className="def-btn text-center def-blue login__button"
                style={{
                  whiteSpace: "nowrap",
                  background: "#5B8ABE",
                  display: "inline-block",
                  minWidth: "240px",
                  height: "50px",
                  lineHeight: "50px",
                }}
              >
                Add USDC to Wallet{" "}
              </button>
            </div>

            <div className="btn-wrap text-center mt-4 sec-title">
              <button
                onClick={handleAddFmToken}
                className="def-btn text-center def-blue login__button"
                style={{
                  whiteSpace: "nowrap",
                  background: "#5B8ABE",
                  display: "inline-block",
                  minWidth: "300px",
                  height: "50px",
                  lineHeight: "50px",
                }}
              >
                Add FM Token to Wallet{" "}
              </button>
            </div>
            <div className="sec-title text-center">
              <h3 style={{ fontSize: "20px" }}>
                Be advised that you will need MATIC (Polygon Native Token) to
                execute the smart contract when you join and to send Polygon
                USDC. <br /> We recommend buying MATIC and then Swapping that
                for Polygon USDC in your MetaMask wallet. Keep a few MATIC for
                future transactions.
              </h3>
            </div>
            <div className="container">
              <div className="benefits-boxes row">
                <div className="col-md-4">
                  <div
                    className="item wow fadeInUp"
                    style={{ background: "#2b1a00", borderRadius: "30px" }}
                    data-wow-delay="0.2s"
                  >
                    <div className="bf-details ">
                      <div className="bf-image ">
                        <img
                          width="50%"
                          src="/home/images/create.png"
                          alt="Strong teams & Advisors"
                        />
                      </div>
                      <div className="">
                        <h3
                          style={{
                            color: "#fbbd18",
                            fontFamily: "'Neuron Angled', sans-serif",
                            fontWeight: "bolder",
                          }}
                        >
                          Create Wallet
                        </h3>
                        <p style={{ fontWeight: "bolder" }}>
                          Using a Metamask.io browser wallet, connect to the
                          Polygon network and click Buy to purchase MATIC and
                          USDC.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="item wow fadeInUp"
                    style={{ background: "#2b1a00", borderRadius: "30px" }}
                    data-wow-delay="0.2s"
                  >
                    <div className="bf-details row">
                      <div className="bf-image ">
                        <img
                          width="50%"
                          src="/home/images/register.png"
                          alt="Strong teams & Advisors"
                        />
                      </div>
                      <div className="">
                        <h3
                          style={{
                            color: "#fbbd18",
                            fontFamily: "'Neuron Angled', sans-serif",
                            fontWeight: "bolder",
                          }}
                        >
                          Register
                        </h3>
                        <p style={{ fontWeight: "bolder" }}>
                          Register on the site. If someone invited you, use
                          their referral link / ID number.
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="item wow fadeInUp"
                    style={{ background: "#2b1a00", borderRadius: "30px" }}
                    data-wow-delay="0.2s"
                  >
                    <div className="bf-details row">
                      <div className="bf-image ">
                        <img
                          width="50%"
                          src="/home/images/activate.png"
                          alt="Strong teams & Advisors"
                        />
                      </div>
                      <div className="">
                        <h3
                          style={{
                            color: "#fbbd18",
                            fontFamily: "'Neuron Angled', sans-serif",
                            fontWeight: "bolder",
                          }}
                        >
                          Activate
                        </h3>
                        <p style={{ fontWeight: "bolder" }}>
                          Activating multiple stages in the Forced Matrix DAO
                          system is the fastest way o increase your income.
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">

            <iframe width="860" height="515" src="https://www.youtube.com/embed/03eG5bc2WV4?si=dY7tVT0aQxX92fOW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div
            id="counter"
            className="hero-main layout-3 white-sec milestone-section p-tb c-l"
            style={{
              justifyContent: "center",
              alignItems: "center",
              backgroundImage: "url('/home/images/banner.jpg')",
            }}
          >
              
            <div id="gold-tech-bg"></div>
            <div
              className="container"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <div className="row">
                <div className="col">
                  <div className="counter">
                    <div className="counter-icon">
                      <img
                        src="/home/images/participants.png"
                        width=""
                        alt=""
                      />
                    </div>
                    <div
                      className="counter-value1"
                      data-count="0"
                      style={{
                        fontSize: "45px",
                        fontWeight: "700",
                        fontFamily: "'Neuron Angled', sans-serif",
                      }}
                    >
                      {totalMembers}
                    </div>
                    <span
                      className="count-text"
                      style={{
                        fontSize: "18px",
                        fontFamily: "'Neuron', 'Poppins'",
                      }}
                    >
                      Total
                      <br />
                      Participants
                    </span>
                  </div>
                </div>

                <div className="col">
                  <div className="counter">
                    <div className="counter-icon">
                      <img src="/home/images/wallets-icon.png" alt="" />
                    </div>
                    <div
                      className="counter-value1"
                      data-count="0"
                      style={{
                        fontSize: "45px",
                        fontWeight: "700",
                        fontFamily: "'Neuron Angled', sans-serif",
                      }}
                    >
                      {totalValue}
                    </div>
                    <span
                      className="count-text "
                      style={{
                        fontSize: "18px",
                        fontFamily: "'Neuron', 'Poppins'",
                      }}
                    >
                      Total
                      <br />
                      earned, USDC
                    </span>
                  </div>
                </div>
                <div className="col">
                  <div className="counter">
                    <div className="counter-icon">
                      <img src="/home/images/wallets-icon.png" alt="" />
                    </div>
                    <div
                      className="counter-value1"
                      data-count="0"
                      style={{
                        fontSize: "45px",
                        fontWeight: "700",
                        fontFamily: "'Neuron Angled', sans-serif",
                      }}
                    >
                      {totalFMT}
                    </div>
                    <span
                      className="count-text "
                      style={{
                        fontSize: "18px",
                        fontFamily: "'Neuron', 'Poppins'",
                      }}
                    >
                      Total
                      <br />
                      FMT Distributed
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="section exampless will-animate"
            style={{ background: "black" }}
          >
            <div className="section-content examples-content">
              <div className="examples-title">
                <div className="default-title revealator-slideleft revealator-once revealator-above revealator-within">
                  <h2 style={{ fontSize: "50px" }}>
                    <span>Matrix Payout System</span>{" "}
                  </h2>
                </div>
              </div>
              <div className="examples-blocks container">
                <div className="text-center row">
                  <div
                    style={{ marginTop: "80px" }}
                    className="col-md-6 order-lg-1 order-2"
                  >
                    <img src="/logo/Forced-Matrix-Breakdown.png" width="80%" />
                    <div className="ml-4" style={{ display: "flex" }}>
                      <div className="example-container text-center">
                        <div
                          className="example-row"
                          style={{
                            padding: "10px",
                            paddingBottom: "0px",
                            paddingLeft: "0px",
                          }}
                        >
                          <img
                            src="/logo/repeat_icon.png"
                            style={{ paddingRight: "20px" }}
                            alt="repeat"
                          />
                          <span style={{ fontSize: "17px", color: "#fff" }}>
                            Lifetime automatic stage reinvestment.
                          </span>
                        </div>
                        <div
                          className="example-row mb-5"
                          style={{
                            padding: "5px",
                            paddingTop: "0px",
                            paddingLeft: "0px",
                          }}
                        >
                          <img
                            src="/logo/wallet_icon.png"
                            style={{ paddingRight: "20px" }}
                            alt="wallet"
                          />
                          <span style={{ fontSize: "17px", color: "#fff" }}>
                            Instant payouts to your personal wallet
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-lg-2 order-1">
                    <div
                      className="examples-more__text revealator-slideleft revealator-once revealator-above revealator-within"
                      style={{
                        color: "white",
                        fontSize: "35px",
                        fontFamily: "Raleway, sans-serif",
                      }}
                    >
                      How Does It Work ?
                    </div>
                    <div className="sub-txt text-center mt-5">
                      <p className="how mt-3" style={{ fontSize: "18px" }}>
                        Starting with only $10, you can scale up your future
                        income to millions using our proven forced matrix system
                        consisting of fifteen 2x4 forced matrix stages that
                        automatically pay you and recycle you to keep you
                        earning repeatedly. As you proceed from one matrix stage
                        to another, you will get 5.1 times what you put in,
                        allowing you to pay your way into the following matrix
                        manually. Plus, with every stage, you will get F.M.
                        tokens to access future exclusive forced matrix projects
                        and events.
                      </p>
                      <p className="how mt-5" style={{ fontSize: "18px" }}>
                        Since this is a forced matrix system, there will be
                        spillover from your upline and downline, helping your
                        income grow faster. For the best results, we recommend
                        referring at least two people to the system and having
                        your referrals do the same. Of course, the more, the
                        better, and you get a whopping 40% per direct referral.
                        Please download our PDF Guide at the top of the website
                        to learn more.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="" style={{ padding: "0px 0px 50px" }}>
                  {/* <div
                    className="examples-more__text revealator-slideleft revealator-once revealator-above revealator-within"
                    style={{ color: "white", fontSize: "24px" }}
                  >
                    Simultaneous entry into platform one of the matrices <br />{" "}
                    occurs automatically upon registration
                  </div> */}

                  <a
                    href="/register"
                    className="def-btn def-purple pulse"
                    style={{
                      position: "relative",
                      fontSize: "23px",
                      minHeight: "50px",
                    }}
                  >
                    10 USDC to join
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="container-fluid"
            style={{ background: "black", marginTop: "0" }}
          >
            <div className="results-title" style={{ marginTop: "0" }}>
              <div className="default-title revealator-slideleft revealator-once revealator-within">
                <h2>
                  <span>User-Friendly </span>Dashboard
                </h2>
              </div>
              <div className="sub-txt text-center"></div>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="results-subtitle revealator-slideleft revealator-once revealator-within"
            >
              <img
                style={{ display: "inline" }}
                src="/logo/dashboard-image-updated.png"
                alt=""
              />
            </div>
          </div>

          <div className="">
            <div className="demo">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="panel-group"
                      id="accordion"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      <div className="mt-5 text-center">
                        <h1 style={{ fontSize: "40px", fontWeight: "bold" }}>
                          Frequently Asked Questions
                        </h1>
                      </div>
                      <div className="panel panel-default mt-5">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingOne"
                        >
                          <h4 className="panel-title">
                            <a
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              WHAT IS THE FORCED MATRIX DAO?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseOne"
                          className="panel-collapse collapse in"
                          role="tabpanel"
                          aria-labelledby="headingOne"
                        >
                          <div className="panel-body">
                            <p>
                              The Forced Matrix DAO (Decentralized Autonomous
                              Organization) networking income system is the
                              international community of the decentralized
                              global ecosystem and the first smart-contract
                              matrix on the Polygon network. This self-executing
                              software algorithm performs the function of
                              distributing partner rewards between community
                              members. The contract code is publicly available.
                              You can view Information about transactions on the
                              Polygon network at polygonscan.com
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingTwo"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              WHO MANAGES THE PLATFORM?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseTwo"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingTwo"
                        >
                          <div className="panel-body">
                            <p>
                              The Forced Matrix DAO platform uses a smart
                              contract with self-executing trades, which does
                              not permit anyone to interfere with the course of
                              the transactions.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              WHO CREATED Forced Matrix DAO?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree"
                        >
                          <div className="panel-body">
                            <p>
                              The Forced Matrix DAO concept belongs to a group
                              of crypto enthusiasts, who are members of the
                              community and don't have any special privileges.
                            </p>
                            <p>
                              Today, Forced Matrix DAO is a peer-to-peer
                              community of platform members, to whom the
                              platform itself belongs.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree2"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree2"
                              aria-expanded="false"
                              aria-controls="collapseThree2"
                            >
                              WHAT IS A SMART CONTRACT? WHAT ARE ITS ADVANTAGES?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree2"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree2"
                        >
                          <div className="panel-body">
                            <p>
                              A smart contract is an algorithm within a
                              cryptocurrency's blockchain. In our case, USDC,
                              similar to US Dollar on the Polygon blockchain, is
                              our first choice among those on which it is
                              possible to create smart contracts. The primary
                              purpose of such contracts is the automation of the
                              relationship, the opportunity to make a commitment
                              self-executing.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree3"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree3"
                              aria-expanded="false"
                              aria-controls="collapseThree3"
                            >
                              WHAT IS DECENTRALIZATION?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree3"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree3"
                        >
                          <div className="panel-body">
                            <p>
                              The process works based on distributing authority
                              from a centralized administration to the
                              participants involved in the process. Unlike a
                              centralized system, all decisions are made by
                              consensus.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree4"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree4"
                              aria-expanded="false"
                              aria-controls="collapseThree4"
                            >
                              WHAT DO I NEED TO DO IN ORDER TO JOIN THE
                              COMMUNITY?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree4"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree4"
                        >
                          <div className="panel-body">
                            <p>
                              To join the community, install the MetaMask.io
                              wallet on your computer’s internet browser,
                              connect to the Polygon Network, and click Buy.
                              Choose USDC Polygon and purchase enough USDC to
                              pay for the stages you want to join. You will need
                              at least $10 USDC to pay for Stage 1 and some
                              MATIC (polygon native token) for network
                              transaction fees. The transaction fee is less than
                              $1.00 worth of MATIC. You can alternatively swap
                              USDC for MATIC or vice/versa using your MetaMask
                              wallet.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree5"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree5"
                              aria-expanded="false"
                              aria-controls="collapseThree5"
                            >
                              WHICH WALLET SHOULD I USE?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree5"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree5"
                        >
                          <div className="panel-body">
                            <p>
                              Your wallet will be your secure way to access the
                              system. We suggest using MetaMask.io or
                              TrustWallet.com browser wallets. Please write down
                              your wallet recovery secret phrase and place it
                              safely when creating your wallet. We cannot access
                              your wallet if your computer is damaged or has
                              stopped working.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree6"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree6"
                              aria-expanded="false"
                              aria-controls="collapseThree6"
                            >
                              WHERE CAN I GET MORE INFORMATION ABOUT Forced
                              Matrix DAO?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree6"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree6"
                        >
                          <div className="panel-body">
                            <p>
                              You can download the brochures at the top of the
                              website. You can also contact us directly at
                              forcedmatrix@proton.me with any questions or
                              concerns.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree8"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree8"
                              aria-expanded="false"
                              aria-controls="collapseThree8"
                            >
                              HOW TO BUY / SELL USDC IF I HAVE NEVER DEALT WITH
                              CRYPTOCURRENCY?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree8"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree8"
                        >
                          <div className="panel-body">
                            <p>
                              There are many ways to buy, sell, and spend USDC.
                              Some great places to check out include
                              Juno.finance, Moonpay.com, Crypto.com, and
                              Bidali.com, and buy directly inside the MetaMask
                              and TrustWallet. Make sure you’re connected to the
                              Polygon Network when buying USDC from inside your
                              wallet
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree9"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree9"
                              aria-expanded="false"
                              aria-controls="collapseThree9"
                            >
                              HOW CAN I REGISTER ON THE Forced Matrix DAO
                              PLATFORM?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree9"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree9"
                        >
                          <div className="panel-body">
                            <p>
                              To register with Forced Matrix DAO, you need to
                              switch to the Polygon chain in your MetaMask
                              wallet and send 10 USDC to a smart contract to
                              activate Stage 1. Click on Registration to start
                              the process. Please make sure the person who
                              referred you is getting credit by double checking
                              the ID Number is theirs.
                            </p>
                            <p>
                              Please note: You will need a small amount of MATIC
                              (Polygon native token), less than $1 worth, to pay
                              for network fees. This small fee is paid to the
                              decentralized computers that process smart
                              contract transactions on the Polygon network.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree10"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree10"
                              aria-expanded="false"
                              aria-controls="collapseThree10"
                            >
                              CAN I REGISTER ON THE WEBSITE WITHOUT A PARTNER
                              LINK?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree10"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree10"
                        >
                          <div className="panel-body">
                            <p>
                              Yes. Registration without an invitation link will
                              put you in the id 1 team.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree11"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree11"
                              aria-expanded="false"
                              aria-controls="collapseThree11"
                            >
                              WHAT WILL HAPPEN TO MY ACCOUNT IF I TAKE A BREAK
                              FROM WORKING WITH THE Forced Matrix DAO COMMUNITY?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree11"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree11"
                        >
                          <div className="panel-body">
                            <p>
                              No one can close your account, even if they have a
                              strong desire to do so. The account will always be
                              saved in one of the Polygon Smart chain blocks.
                              You will continue to receive income from all
                              levels.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree12"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree12"
                              aria-expanded="false"
                              aria-controls="collapseThree12"
                            >
                              I HAVE ACTIVATED THE PLATFORM, WHAT SHOULD I DO
                              NEXT?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree12"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree12"
                        >
                          <div className="panel-body">
                            <p>
                              Get familiar with your dashboard. You will see all
                              the stages you have activated and be able to see
                              your matrix fill and recycle. You will also be
                              able to access your unique referral URL to invite
                              other members to your team.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree13"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree13"
                              aria-expanded="false"
                              aria-controls="collapseThree13"
                            >
                              HOW CAN I REACH MY GOALS WITH Forced Matrix DAO?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree13"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree13"
                        >
                          <div className="panel-body">
                            <p>
                              You tell potential partners about the platform's
                              income potential and encourage them to collaborate
                              with you. Since this is a forced matrix system,
                              there will be spillover from your upline and
                              downline, helping your income grow faster. For the
                              best results, we recommend referring at least two
                              people to the system and having your referrals do
                              the same. Of course, the more, the better, and you
                              get a whopping 40% per direct referral. In
                              addition, you can print our brochure, which can be
                              downloaded at the top of our website, using the
                              double-sided printing setting and place your ID
                              Number in the space provided—post it around town,
                              on bulletin boards, and car windshields.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree14"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree14"
                              aria-expanded="false"
                              aria-controls="collapseThree14"
                            >
                              IS PASSIVE INCOME POSSIBLE?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree14"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree14"
                        >
                          <div className="panel-body">
                            <p>
                              The platform, Forced Matrix DAO, is designed so
                              that all participants in the system help each
                              other. Passive income is possible and depends on
                              partners' activity, who can get into your team
                              through overflows or overtaking. You can receive
                              spillovers from upstream or downstream partners,
                              but your income will depend on their activity. To
                              provide a passive income in the future, you need
                              to make certain efforts - to attract new active
                              partners. Inviting even one very active person to
                              your team can earn money and achieve your goals.
                              How quickly this happens is up to you
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree15"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree15"
                              aria-expanded="false"
                              aria-controls="collapseThree15"
                            >
                              HOW CAN I ATTRACT PEOPLE EFFECTIVELY? WHAT SHOULD
                              I DO IF I CAN’T ATTRACT ANYONE?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree15"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree15"
                        >
                          <div className="panel-body">
                            <p>
                              You don't have to force participation on anyone.
                              Many people are interested in making money on the
                              Internet, and some are looking for new
                              opportunities. You can find them on social
                              networks on your own or set up an automatic sales
                              funnel, and interested people will find you
                              themselves. Blogger.com is a great free place to
                              start an informative blog to attract potential
                              partners. Use Screenrec.com to show people the
                              platform by video and upload it to YouTube for
                              free. Use your referral link and reference your ID
                              number to direct new partners to the website.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree17"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree17"
                              aria-expanded="false"
                              aria-controls="collapseThree17"
                            >
                              DO I NEED TO WITHDRAW MONEY FROM Forced Matrix
                              DAO?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree17"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree17"
                        >
                          <div className="panel-body">
                            <p>
                              No, all payouts happen instantly. Forced Matrix
                              DAO does not retain any funds, so the smart
                              contract balance is equal to zero. Your income
                              arrives immediately into your wallet directly from
                              your partners. Only you can access your wallet,
                              and no one else can manage your money.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree19"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree19"
                              aria-expanded="false"
                              aria-controls="collapseThree19"
                            >
                              DO STAGES HAVE A VALIDITY PERIOD?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree19"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree19"
                        >
                          <div className="panel-body">
                            <p>
                              No, purchased stages never expire. Pay once and
                              you qualify for all future cycles and referral
                              commission from your network for purchased stages.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree20"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree20"
                              aria-expanded="false"
                              aria-controls="collapseThree20"
                            >
                              WHAT IS REPEAT (RE-OPENING)?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree20"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree20"
                        >
                          <div className="panel-body">
                            <p>
                              If there are no free places left on a level, then
                              the next partner gets a place in an automatically
                              created matrix of the same level. This is called
                              Repeat. The partner who took the last place pays
                              for the new matrix, and the amount goes to a
                              higher-ranked partner. This process is entirely
                              automated by the smart contract.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree21"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree21"
                              aria-expanded="false"
                              aria-controls="collapseThree21"
                            >
                              WHAT ARE "OVERFLOWS" AND "OVERTAKES"?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree21"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree21"
                        >
                          <div className="panel-body">
                            <p>
                              Overflows are when vacant places in your stages
                              are filled with partners who were not invited by
                              you. They can come from both an upstream partner
                              and a downstream partner. Overtaking is a
                              situation when your partner does not have a stage
                              that their partner activates and the transaction
                              is sent along the chain to you, provided that you
                              have this stage active. This partner follows you
                              and brings you income until their superior partner
                              activates the missing stages.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree22"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree22"
                              aria-expanded="false"
                              aria-controls="collapseThree22"
                            >
                              CAN I LOSE A PARTNER WHOM I HAVE PERSONALLY
                              INVITED?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree22"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree22"
                        >
                          <div className="panel-body">
                            <p>
                              No, this is out of the question. Your personally
                              invited partner stays with you forever thanks to
                              the referral link
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree24"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree24"
                              aria-expanded="false"
                              aria-controls="collapseThree24"
                            >
                              HOW DOES THIS DIFFER FROM A PYRAMID SCHEME?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree24"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree24"
                        >
                          <div className="panel-body">
                            <p>
                              Forced Matrix DAO doesn't give members unfulfilled
                              promises like a pyramid scheme. Everyone's success
                              depends on himself. Forced Matrix DAO is stable
                              even at 1000x scaling. Forced Matrix DAO does not
                              create debt or other obligations. Forced Matrix
                              DAO is a next generation crowdfunding platform and
                              has nothing to do with pyramids. The principle of
                              a pyramid scheme is based on the fact that most of
                              the money is concentrated in the hands of its
                              creators. The earlier you came, the more you
                              earned. The financial pyramid can close at any
                              time. Members of the Forced Matrix DAO platform -
                              both leaders and newcomers - are on an equal
                              footing. Nobody can stop the work of the platform.
                              its functioning is provided by a smart contract,
                              which cannot be deleted or changed. Even if the
                              site stops working, all data and structure will be
                              intact, and the smart contract will continue to
                              function as long as the Internet and electricity
                              exist.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="headingThree25"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree25"
                              aria-expanded="false"
                              aria-controls="collapseThree25"
                            >
                              WHAT ARE THE RISKS?
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree25"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree25"
                        >
                          <div className="panel-body">
                            <p>
                              There are no risks to the Forced Matrix DAO
                              platform. It is enough to invite 1 person to
                              immediately recoup the initial participation
                              costs. The platform operates on the basis of a
                              smart contract in the blockchain system. The smart
                              contract code is publicly available. All transfers
                              go directly to your personal wallet, without any
                              hidden fees and without the use of third-party
                              resources. This ensures that any amount you earn
                              belongs to you and only you, and you can use the
                              money in any way you want in the very second it
                              entered your wallet.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clear"></div>
        <footer className="diamond-footer">
          <div className="footer-widget-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-4 widget-area logo_clear_first">
                  <div
                    className="widget widget-html"
                    style={{ paddingTop: "30px" }}
                  >
                    <div className="footer-logo">
                      <img
                        src="/logo/1 (1).png"
                        style={{ paddingBottom: "10px", marginTop: "-50px" }}
                      />
                      <span
                        className="text-center"
                        style={{ fontSize: "15px" }}
                      >
                        Copyright © 2022. All Rights Reserved
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-4 widget-area">
                  <div className="widget widget-html text-center">
                    <h2 className="widget-title">Stay connected</h2>
                    <div className="socials">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.facebook.com/forcedmatrix"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://t.me/forcedmatrixdao"
                          >
                            <i className="fab fa-telegram-plane"></i>
                          </a>
                        </li>

                        <li>
                          <a target="_blank" href="#">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://medium.com/@forcedmatrix"
                            target="_blank"
                          >
                            <i className="fab fa-medium-m"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 widget-area">
                  <div className="widget widget-html"></div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Home;

