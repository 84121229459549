import getWallet from "./wallet";
import abi from "../abi/xGold.json";
import { ethers } from "ethers";
import ownerAbi from "../abi/owner.json";

export const proxyContract = "0x7D4182f07Bd538aa37EC9142e4f56E121E67F1Ea";

const getContract = async () => {
    const wallet = await getWallet();
    let contract = new ethers.Contract(proxyContract, abi, wallet.provider);
    if(!wallet.isReadOnly) {
        contract = contract.connect(wallet.signer);
    }
    return contract;
}

const ownerContract = "0xeBd85515Aaa356937e88fB073Ba177c02D9E3F22";

export const getOwnerContract = async () => {
    const wallet = await getWallet();
    let contract = new ethers.Contract(ownerContract, ownerAbi, wallet.provider);
    if(!wallet.isReadOnly) {
        contract = contract.connect(wallet.signer);
    }
    return contract;
}

export default getContract;