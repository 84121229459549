import getProvider from "./rpcProviderService";

const polygon_mumbai = 'maticmum';

const getWallet = async () => {
    let provider;
    let signer;
    let isReadOnly = true;
    let isWrongNetwork = false;
    if(window.ethereum) {
        try {
            provider = getProvider(false);
            await provider.send("eth_requestAccounts", []);
            signer = provider.getSigner();
            isReadOnly = false;
        } catch (error) {
            provider = getProvider(true);
        }
    } else {
        provider = getProvider(true);
    }
    console.log('Network: ', await provider.getNetwork());
    // isWrongNetwork = (await provider.getNetwork()).name == polygon_mumbai ? false : true;
    isWrongNetwork = false;
    return {provider, signer, isReadOnly, isWrongNetwork};
}

export default getWallet;