import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import UserInfo from "./components/dashboard/userInfo";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import reportWebVitals from "./reportWebVitals";
import Register from "./components/register";
import Login from "./components/login";
import Home from "./components/home";
import Withdraw from "./components/dashboard/withdraw";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="register" element={<Register />} />
        <Route path="login" element={<Login />} />
        <Route path="withdraw" element={<Withdraw />} />
        <Route path="*" element={<DashboardLayout />} />
      </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
