import React, { Component } from 'react';

class SidePanel extends Component {
    state = {  
        selectedPanelClass: 'bg-black-light',
        selected: 'Dashboard'
    }

    constructor(props) {
        super(props);
    }
    
    onClick = (_selectedValue) => {
        this.setState({selected: _selectedValue})
        if(_selectedValue === 'Dashboard')
            this.props.onDashboardClick()
        else if (_selectedValue === "Contracts")
            this.props.onContractsClick()
    }

    render() { 
        return (
            <div className={`dash-sidebar relative w-full max-w-desktop-left-bar flex-shrink-0 border-r border-white-100 ${!this.props.sideBarOpened ? "lg:hidden" : "" } `}>
                    <div className="fixed top-0 max-w-desktop-left-bar w-full flex h-screen z-40 pt-4">
                        <div className="flex flex-col min-h-screen w-full flex-shrink-0 pl-10 pr-7.5 pt-22 overflow-auto">
                            <div className="flex-1 lg:pr-0 lg:pl-10 sm:pl-5">
                                <div
                                    className="flex flex-1 flex-col h-full w-full overflow-y-auto space-y-2.5 lg:space-y-0">
                                    <div
                                        className="flex flex-1 flex-col w-full h-full overflow-y-auto space-y-2.5 lg:space-y-0 lg:mb-5">
                                        <a >
                                            <button onClick={() => this.onClick('Dashboard')}
                                                className={"relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent lg:bg-transparent lg:border-white-300 lg:pr-5 lg:py-5 lg:justify-between " + (this.state.selected == 'Dashboard' ? this.state.selectedPanelClass : '')}>
                                                <div className="flex items-center text-left">
                                                    <svg className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                                                        viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M9 4H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1ZM19 4h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1ZM9 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1Z"
                                                            fill="#fff" stroke="#fff" strokeLinecap="round" 
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M19 14h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1Z"
                                                            fill="#8B8C8C" stroke="#8B8C8C" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                    </svg>
                                                    <span
                                                        className="text-white text-base ml-5 text-white-900">Dashboard</span>
                                                </div>
                                            </button>
                                        </a>
                                        <a>
                                            <button onClick={() => this.onClick('Contracts')}
                                                    className={"relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between " + (this.state.selected == 'Contracts' ? this.state.selectedPanelClass : '')}>
                                                <div className="flex items-center text-left">
                                                <svg className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                                                         viewBox="0 0 24 24" stroke="#fff" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M16 12h5m-9-9v5-5ZM8.929 14.582 5.5 17.5M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
                                                            strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
                                                              strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                    <span className="text-white text-base ml-5 false">Smart Contracts</span>
                                                </div>
                                            </button>
                                        </a>
                                        <a href="/Token.pdf" target="_blank" 
                                            className='relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between'>

                                                <div className="flex items-center text-left">
                                                <svg className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                                                         viewBox="0 0 24 24" stroke="#fff" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M16 12h5m-9-9v5-5ZM8.929 14.582 5.5 17.5M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
                                                            strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
                                                              strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                    <span className="text-white text-base ml-5 false">FMT PDF</span>
                                                </div>
                                        </a>
                                        <a href="/Brochure.pdf" target="_blank"
                                            className='relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between'>

                                                <div className="flex items-center text-left">
                                                <svg className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                                                         viewBox="0 0 24 24" stroke="#fff" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M16 12h5m-9-9v5-5ZM8.929 14.582 5.5 17.5M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
                                                            strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
                                                              strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                    <span className="text-white text-base ml-5 false">DAPP PDF</span>
                                                </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-auto ">
                                <div className="pt-5 pb-5 px-2 w-full flex flex-col items-center justify-center">
                                    <div className="flex space-x-5 justify-between w-full">
                                        <a className="w-6 h-6 sm:w-7 sm:h-7 flex justify-center items-center rounded-full bg-white-100 hover:bg-white-300"
                                            target="_blank" href="https://t.me/forcedmatrixdao">
                                            <svg className="w-3 h-3 sm:w-4 sm:h-4" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                    d="M11.915.776.841 5.168a.4.4 0 0 0 .03.755l2.79.864 1.133 3.503a.4.4 0 0 0 .653.17l1.64-1.523 3.11 2.29a.4.4 0 0 0 .627-.231l2.166-9.294a.8.8 0 0 0-1.075-.926Zm-6.483 6.74-.346 2.072-.8-2.901 7.816-5.1-6.67 5.929Z"
                                                    fill="#fff"></path>
                                            </svg>
                                        </a>
                                        <div className="">
                                            <a className="w-6 h-6 sm:w-7 sm:h-7 flex justify-center items-center rounded-full bg-white-100 hover:bg-white-300 mx-auto"
                                                href="/login">
                                                <svg className="w-3 h-3 sm:w-4 sm:h-4" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 32 32">
                                                    <path
                                                        d="M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 20.050781 28 23.640625 25.988281 25.8125 22.90625 L 24.1875 21.75 C 22.378906 24.320313 19.390625 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 10.464844 10.464844 6 16 6 C 19.390625 6 22.375 7.679688 24.1875 10.25 L 25.8125 9.09375 C 23.640625 6.011719 20.050781 4 16 4 Z M 23.34375 11.28125 L 21.90625 12.71875 L 24.1875 15 L 12 15 L 12 17 L 24.1875 17 L 21.90625 19.28125 L 23.34375 20.71875 L 27.34375 16.71875 L 28.03125 16 L 27.34375 15.28125 Z"
                                                        fill="#fff" />
                                                </svg>
                                            </a>
                                            <span className="text-white-500 text-xs font-normal lg:mb-2.5">Disconnect</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}
 
export default SidePanel;