import React, { Component } from 'react';
import getContract from '../../services/contract';
import getTokenContract from '../../services/tokenContract'
import { ethers } from 'ethers';
import levelPrices from '../../services/levelPrice';
import getWallet from '../../services/wallet';
import Slot from './tree/slot';
import { TransactionService } from '../../services/transactionService';
import abi from "../../abi/owner.json";

class Withdraw extends Component {

    state = {
    };

    withdraw = async () => {
        const wallet = await getWallet();
        if(wallet.isReadOnly) {
            alert("Please connect your wallet");
            return;
        }
        const userBrowserAddresses = await wallet.provider.listAccounts();
        
        const onwer_address = '0xeBd85515Aaa356937e88fB073Ba177c02D9E3F22';
        let contract = new ethers.Contract(onwer_address, abi, wallet.provider);
        contract = contract.connect(wallet.signer);
        
        await contract.withdraw();
        alert('Successfully amount withdrawn to 2 wallets');
    }

    render() { 
        return (
            <div className="flex flex-shrink w-full flex-col items-center min-h-screen bg-main pt-20 sm:pt-14">
                    <div className="flex relative flex-1 py-2 pt-8.5 flex-col justify-between w-full px-10 sm:px-0 sm:pt-7.5">
                        {/* Top Panel, Matrix levels and all activies inside below div */}
                        <div className="flex flex-col w-full space-y-10 sm:space-y-5">

                            {/* Top panel Partners, Team, Profit ration, Profits View inside below div */}
                            <div className="flex w-full mt-15 sm:mt-5 sm:px-5">
                                <div className="flex w-full flex-wrap lg:flex-col">
                  
                                <button style={{background: 'white'}}
                                        className="flex justify-center items-center text-center text-base font-bold text-black rounded-mini sm:text-sm outline-none px-5 py-4 bg-blue-600 hover:bg-blue-400 active:bg-active-red-600 mt-10 py-5 !px-10 sm:py-5 sm:mt-7.5"
                                        onClick={this.withdraw} id="nextButton" >Withdraw </button>
                                </div>
                            </div>
                            {/* Top panel Partners, Team, Profit ration, Profits View inside above div */}

                        </div>
                        {/* Top Panel, Matrix levels and all activies inside above div */}
                    </div>
            </div>
        );
    }
}
 
export default Withdraw;