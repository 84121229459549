import { useEffect } from 'react';

const useCss = (rel, url, type='', crossorigin='', integrity='') => {
  useEffect(() => {
    const css = document.createElement('link');

    css.href = url;
    css.rel = rel;

    if(type) css.type = type;
    if(crossorigin) css.crossOrigin = crossorigin;
    if(integrity) css.integrity = integrity;

    document.head.prepend(css);

    return () => {
      document.head.removeChild(css);
    }
  }, [url]);
};

export default useCss;