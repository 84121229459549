import { ethers } from "ethers";

const levelPrices = [
  10, 20, 40, 80, 160, 320, 640, 1280, 2500, 5000, 10000, 20000, 40000, 80000,
  160000,
];
export const lpFees = [
  "1",
  "2",
  "4",
  "8",
  "16",
  "32",
  "64",
  "128",
  "256",
  "512",
  "1024",
  "2048",
  "4096",
  "8192",
  "16384",
];

export const tokenRewards = [
  "2", "4", "8", "16", "32", "64", "128", "256", "512", "1024", "2048", "4096", "8192", "16384", "32768"
]

export default levelPrices;
