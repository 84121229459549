import getWallet from "./wallet";
import abi from "../abi/token.json";
import fmTokenAbi from "../abi/fmToken.json";
import { ethers } from "ethers";

const tokenAddress = "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359"; // USDC
// const tokenAddress = "0xC174Be5bbC0594B031ADb2cCa021E8feeED706f6";

const getTokenContract = async () => {
    const wallet = await getWallet();
    let contract = new ethers.Contract(tokenAddress, abi, wallet.provider);
    if(!wallet.isReadOnly) {
        contract = contract.connect(wallet.signer);
    }
    return contract;
}

// export const fmTokenAddress = "0xbbF3231e29f6F8230A99Cb48906DE32F58bce816";
export const fmTokenAddress = "0x3d19E000C360144CcaA8a3C2403126AB27A27225";

export const getFMTokenContract = async () => {
    const wallet = await getWallet();
    let contract = new ethers.Contract(fmTokenAddress, fmTokenAbi, wallet.provider);
    if(!wallet.isReadOnly) {
        contract = contract.connect(wallet.signer);
    }
    return contract;
}

export default getTokenContract;